import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { some } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { AdministrationAbstract } from '@main-application/administration/abstract/administration-abstract';
import { VENDOR_ROLE_NAME } from '@main-application/administration/constants/vendor-role-name.constant';
import { combineUserWithProperties } from '@main-application/administration/functions/combineUserWithProperties';
import { formatPropertyStructureToTree } from '@main-application/administration/functions/propertyStructureToTree';
import { prepareSelectedUserToPropertyMappings } from '@main-application/administration/functions/userToPropertyMappingHandlers';
import {
  clearUserPreviewData,
  inviteNewUser,
  loadAllGeneralRoles,
  updateUserData,
} from '@main-application/administration/store/actions/administration.actions';
import {
  selectAllPropertiesStructure,
  selectAllUserToPropertyMappings,
  selectAllUsersWithGeneralRoles,
  selectAssignUserIntoPropertyInProgress,
  selectAssignUserIntoPropertyStatus,
  selectInactiveUsers,
  selectRemovingPropertyAssignedUserInProgress,
  selectRemovingPropertyAssignedUserStatus,
  selectSaveUserToGeneralRoleInProgress,
  selectSaveUserToGeneralRoleStatus,
  selectUserInviteInProgress,
  selectUserInviteStatus,
  selectUserInvited,
  selectUserUpdateInProgress,
  selectUserUpdateStatus,
} from '@main-application/administration/store/selectors/administration.selectors';
import { UserPreview, UserPreviewFields } from '@main-application/administration/users/config/enums/user-preview';
import { loadAllUserProperties } from '@main-application/store/actions/user.actions';
import { selectNativeLanguagesEnumeration } from '@main-application/store/selectors/enumeration.selectors';
import { selectUserData } from '@main-application/store/selectors/user.selectors';
import { getCompanyList } from '@main-application/turnovers/store/actions/turnovers.actions';
import {
  selectCompanyList,
  selectCompanyListLoading,
} from '@main-application/turnovers/store/selectors/turnovers.selectors';
import { loadAllUserPortfolios } from '@portfolio/store/portfolio.actions';
import { UpdateStatus } from '@shared/enums/update-status';
import { UserType } from '@shared/enums/user-type';
import { getCompanyRadioList } from '@shared/functions/get-company-radio-list.function';
import { getEnumerationRadioListFunction } from '@shared/functions/get-enumeration-radio-list.function';
import { RestCompanyModel } from '@shared/interfaces/companies.interface';
import { PropertyBasicInfo } from '@shared/interfaces/propertyBasicInfo';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserData } from '@shared/interfaces/user-data';
import { UserToPropertyMapping } from '@shared/interfaces/user-to-property-mapping';
import { UserWithGeneralRole } from '@shared/interfaces/user-with-general-role';
import { UserWithProperties } from '@shared/interfaces/user-with-properties';
import { InviteRestUserModel, RestUserModel, UpdateRestUserModel } from '@shared/interfaces/user.interface';
import { UserExistsValidator } from '@shared/validators/user-exists.validator';
import { UserInactiveValidator } from '@shared/validators/user-inactive.validator';
import { DialogResult } from '@ui-components/modals/config/dialog-result.enum';
import { ModalsService } from '@ui-components/modals/modals.service';
import { EmailPatternValidator, correctEmailInput } from '@ui-components/validators/email.validator';

export interface UserPreviewModalData {
  vendorId?: number;
  header?: string;
  user: UserWithGeneralRole;
  preselect: boolean;
}

export interface UserPreviewModalResult {
  invitedUser: RestUserModel;
}

const SuperUserRole = 1;

@UntilDestroy()
@Component({
  selector: 'app-user-preview-modal',
  templateUrl: './user-preview-modal.component.html',
  styleUrls: ['./user-preview-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserPreviewModalComponent extends AdministrationAbstract implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  warningMessage = '';
  selectedProperties: PropertyBasicInfo[] = [];
  propertiesToSelect: PropertyBasicInfo[] = [];
  arePropertiesChanged = false;
  root = [];
  isSubmitted = false;
  nativeLanguages: IRadioButtonOption<number>[] = [];
  companyRadioList: IRadioButtonOption<number>[] = [];
  companyListLoading$: Observable<boolean>;

  userToPropertyMapping: UserToPropertyMapping[];
  selectedUsersWithProperties: UserWithProperties[];

  header: string;
  saveButtonText: string;
  isSuperUser: boolean;
  skipDialogClose = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected store: Store<{}>,
    public dialogRef: MatDialogRef<UserPreviewModalComponent, UserPreviewModalResult>,
    @Inject(MAT_DIALOG_DATA) public data: UserPreviewModalData,
    private formBuilder: FormBuilder,
    private modalsService: ModalsService
  ) {
    super(cdr, store);

    this.header = data.header || (data.user && !data.preselect ? 'Edit user' : 'Add new user');
    this.saveButtonText = data.vendorId
      ? 'Add and Assignee'
      : data.user && !data.preselect
      ? 'Save'
      : 'Send invitation';
  }

  get firstName(): FormControl<string> {
    return this.form.get(UserPreviewFields.FirstName) as FormControl<string>;
  }

  get lastName(): FormControl<string> {
    return this.form.get(UserPreviewFields.LastName) as FormControl<string>;
  }

  get email(): FormControl<string> {
    return this.form.get(UserPreviewFields.Email) as FormControl<string>;
  }

  get userGroupType(): FormControl<UserType> {
    return this.form.get(UserPreviewFields.UserGroupType) as FormControl<UserType>;
  }

  get roleId(): FormControl<number> {
    return this.form.get(UserPreviewFields.RoleId) as FormControl<number>;
  }

  get companyId(): FormControl<number> {
    return this.form.get(UserPreviewFields.CompanyId) as FormControl<number>;
  }

  get phoneNumber(): FormControl {
    return this.form.get(UserPreviewFields.PhoneNumber) as FormControl;
  }

  get nativeLanguage(): FormControl {
    return this.form.get(UserPreviewFields.NativeLanguage) as FormControl<number>;
  }

  ngOnInit(): void {
    this.initForm();
    this.store.dispatch(getCompanyList());
    this.store.dispatch(loadAllGeneralRoles());
    this.companyListLoading$ = this.store.select(selectCompanyListLoading);
    this.selectAllGeneralRoles().subscribe();
    this.selectGeneralRoleRadioList().subscribe();

    this.store
      .select(selectCompanyList)
      .pipe(
        untilDestroyed(this),
        filter((companyList: RestCompanyModel[]) => !!companyList),
        tap((companyList: RestCompanyModel[]) => {
          this.companyRadioList = getCompanyRadioList(companyList);
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.store
      .select(selectNativeLanguagesEnumeration)
      .pipe(
        untilDestroyed(this),
        tap(values => {
          this.nativeLanguages = getEnumerationRadioListFunction(values, null, false);
          if (this.data.user) {
            this.nativeLanguage.setValue(this.data.user.nativeLanguage);
          }
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.store
      .select(selectUserData)
      .pipe(
        untilDestroyed(this),
        filter((userData: UserData) => !!userData),
        tap((userData: UserData) => {
          this.userData = userData;
        })
      )
      .subscribe();

    combineLatest([this.store.select(selectAllUsersWithGeneralRoles), this.store.select(selectInactiveUsers)])
      .pipe(
        untilDestroyed(this),
        filter(([allUsers, inactiveUsers]) => !!allUsers && !!inactiveUsers),
        tap(([allUsers, inactiveUsers]: [UserWithGeneralRole[], RestUserModel[]]) => {
          const existingActiveEmails = allUsers.map(item => item.email);
          const existingInactiveEmails = inactiveUsers.map(item => item.email);

          if (!this.data.user) {
            this.email.addValidators(UserExistsValidator(existingActiveEmails));
            this.email.addValidators(UserInactiveValidator(existingInactiveEmails));
          }
        })
      )
      .subscribe();

    combineLatest([this.store.select(selectAllPropertiesStructure), this.store.select(selectAllUserToPropertyMappings)])
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(([allProperties, userToPropertyMapping]) => !!allProperties && !!userToPropertyMapping),
        tap(([allProperties, userToPropertyMapping]) => {
          this.userToPropertyMapping = userToPropertyMapping;
          this.root = formatPropertyStructureToTree(allProperties);
          const rootNodes = allProperties.filter(item => !item.parentId);
          if (this.data.user || this.data.preselect) {
            this.propertiesToSelect = combineUserWithProperties(
              this.data.user.previousAddedUserId || this.data.user.id,
              allProperties,
              userToPropertyMapping
            );
          } else {
            this.propertiesToSelect = rootNodes;
          }
          this.cdr.detectChanges();
        })
      )
      .subscribe();

    this.userGroupType.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((userGroupType: UserType) => {
          const validators = userGroupType === UserType.Contractor ? [Validators.required] : [];
          if (userGroupType === UserType.Contractor) {
            const vendroRole = this.userRoles.find(x => x.label === VENDOR_ROLE_NAME);
            if (vendroRole) {
              this.roleId.setValue(vendroRole.value);
            }
          } else {
            this.roleId.setValue(null);
          }
          this.companyId.setValidators(validators);
          this.companyId.updateValueAndValidity();
        })
      )
      .subscribe();

    if (!this.data.preselect && this.data.user) {
      this.inProgress$ = combineLatest([
        this.store.select(selectUserUpdateInProgress),
        this.store.select(selectSaveUserToGeneralRoleInProgress),
        this.store.select(selectAssignUserIntoPropertyInProgress),
        this.store.select(selectRemovingPropertyAssignedUserInProgress),
      ]).pipe(map(operationsInProgress => some(operationsInProgress, Boolean)));

      combineLatest([
        this.store.select(selectUserUpdateStatus),
        this.store.select(selectSaveUserToGeneralRoleStatus),
        this.store.select(selectAssignUserIntoPropertyStatus),
        this.store.select(selectRemovingPropertyAssignedUserStatus),
      ])
        .pipe(
          untilDestroyed(this),
          filter(
            ([
              userUpdateStatus,
              saveUserToGeneralRoleStatus,
              assignUserIntoPropertyStatus,
              removingPropertyAssignedUserStatus,
            ]) =>
              userUpdateStatus === UpdateStatus.UPDATED &&
              saveUserToGeneralRoleStatus === UpdateStatus.UPDATED &&
              (this.arePropertiesChanged
                ? assignUserIntoPropertyStatus === UpdateStatus.UPDATED ||
                  removingPropertyAssignedUserStatus === UpdateStatus.DELETED
                : true)
          ),
          tap(() => {
            if (this.arePropertiesChanged && this.userData?.id === this.data.user?.id) {
              this.store.dispatch(loadAllUserProperties());
              this.store.dispatch(loadAllUserPortfolios());
            }

            this.skipDialogClose ? (this.skipDialogClose = false) : this.close(DialogResult.Success);
          })
        )
        .subscribe();
    } else {
      this.inProgress$ = combineLatest([
        this.store.select(selectUserInviteInProgress),
        this.store.select(selectSaveUserToGeneralRoleInProgress),
        this.store.select(selectAssignUserIntoPropertyInProgress),
      ]).pipe(map(operationsInProgress => some(operationsInProgress, Boolean)));

      combineLatest([
        this.store.select(selectUserInvited),
        this.store.select(selectUserInviteStatus),
        this.store.select(selectSaveUserToGeneralRoleStatus),
        this.store.select(selectAssignUserIntoPropertyStatus),
      ])
        .pipe(
          untilDestroyed(this),
          filter(
            ([invitedUser, userInviteStatus, saveUserToGeneralRoleStatus, assignUserIntoPropertyStatus]) =>
              invitedUser &&
              userInviteStatus === UpdateStatus.OK &&
              saveUserToGeneralRoleStatus === UpdateStatus.UPDATED &&
              assignUserIntoPropertyStatus === UpdateStatus.UPDATED
          )
        )
        .subscribe(([invitedUser]) => this.close(DialogResult.Success, { ...invitedUser, roleId: this.roleId.value }));
    }

    if (this.data.vendorId) {
      this.userGroupType.setValue(UserType.Contractor);
      this.companyId.setValue(this.data.vendorId);
    }

    this.roleId.valueChanges.pipe(untilDestroyed(this)).subscribe(roleId => {
      this.isSuperUser = this.checkUserRole(roleId);
    });
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.isSubmitted = true;
      this.cdr.detectChanges();
      return;
    }

    const { firstName, lastName, roleId, email, userGroupType, companyId, nativeLanguage, phoneNumber } = this.form
      .value as UserPreview;
    let userToPropertyRoleList: UserToPropertyMapping[] = [];
    let deleteUserToPropertyMappings: number[] = [];
    let selectedProperty: number[] = [];

    const updateUser: UpdateRestUserModel = {
      id: this.data.user?.id || 0,
      firstName,
      lastName,
      companyId,
      nativeLanguage,
      phoneNumber,
    };

    const inviteUser: InviteRestUserModel = {
      email,
      firstName,
      lastName,
      userGroupType,
      displayName: `${firstName} ${lastName}`,
      companyId: userGroupType === UserType.Contractor ? companyId : null,
      nativeLanguage,
      phoneNumber,
    };

    const currentUserProperties = this.userToPropertyMapping?.filter(
      item => item.userId === this.data.user?.previousAddedUserId || item.userId === this.data.user?.id
    );
    const currentUserPropertyIds = currentUserProperties
      ? currentUserProperties.map<number>(item => item.propertyId)
      : [];

    if (this.arePropertiesChanged) {
      if (!this.data.preselect && this.data.user) {
        const newUserToPropertyMappings = prepareSelectedUserToPropertyMappings(
          this.data.user,
          this.selectedProperties,
          roleId
        );

        const selectedPropertyIds = newUserToPropertyMappings.map<number>(value => value.propertyId);

        const propertyToDelete = this.propertiesToSelect
          .filter(item => !selectedPropertyIds.includes(item.id))
          .map<number>(item => item.id);

        deleteUserToPropertyMappings = currentUserProperties
          .filter(item => propertyToDelete.includes(item.propertyId))
          .map<number>(item => item.id);

        userToPropertyRoleList = newUserToPropertyMappings.filter(
          item => !currentUserPropertyIds.includes(item.propertyId)
        );
      } else {
        selectedProperty = this.selectedProperties.map(item => item.id);
      }
    }

    if (!this.data.preselect && this.data.user) {
      this.store.dispatch(
        updateUserData({ user: updateUser, roleId, userToPropertyRoleList, deleteUserToPropertyMappings })
      );
    } else {
      this.store.dispatch(inviteNewUser({ user: inviteUser, roleId, selectedProperty }));
    }
  }

  close(result: DialogResult = DialogResult.Fail, invitedUser?: RestUserModel) {
    if (result === DialogResult.Fail) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close({
        invitedUser,
      });
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearUserPreviewData());
  }

  addNewVendor() {
    this.modalsService
      .openNewVendorModal({})
      .afterClosed()
      .pipe(
        tap(company => {
          if (company) {
            this.companyId.setValue(company.id);
            this.store.dispatch(getCompanyList());
            this.cdr.detectChanges();
          }
        })
      )
      .subscribe();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      [UserPreviewFields.FirstName]: [
        this.data.user?.firstName || null,
        [Validators.required, Validators.maxLength(24)],
      ],
      [UserPreviewFields.LastName]: [this.data.user?.lastName || null, [Validators.required, Validators.maxLength(24)]],
      [UserPreviewFields.Email]: [
        this.data.user?.email || null,
        [Validators.required, EmailPatternValidator(), Validators.email],
      ],
      [UserPreviewFields.UserGroupType]: [this.data.user?.userGroupType || UserType.Employee, [Validators.required]],
      [UserPreviewFields.RoleId]: [this.data.user?.roleId || null, [Validators.required]],
      [UserPreviewFields.CompanyId]: [
        this.data.user?.companyId,
        this.data.user?.userGroupType === UserType.Contractor ? [Validators.required] : [],
      ],
      [UserPreviewFields.PhoneNumber]: [this.data.user?.phoneNumber || null],
      [UserPreviewFields.NativeLanguage]: [this.data.user?.nativeLanguage || 0, [Validators.required]],
    });

    this.isSuperUser = this.checkUserRole(this.data.user?.roleId);
    correctEmailInput(this.email);
  }

  selectedRootsChange($event: PropertyBasicInfo[]) {
    this.arePropertiesChanged = true;
    this.selectedProperties = $event;
  }

  private checkUserRole(roleId: number | undefined): boolean {
    return roleId === SuperUserRole; // Did not find role enumeration
  }
}
