<div class="input-area-container" [ngClass]="containerCss">
  <label [ngClass]="[labelCss, labelRequired ? 'label-required' : '']" *ngIf="label">{{ label }}</label>
  <div class="display-flex flex-column fill">
    <textarea
      aria-label="Input Area"
      rows="1"
      matInput
      appCustomTooltip
      [showToolTip]="displayNewLineHint"
      [contentTemplate]="textAreaTooltipTemplate"
      [appAutoresize]="autoresize"
      [maxHeight]="maxHeight"
      (keydown.enter)="enter($event)"
      (keydown.meta.enter)="insertNewline($event)"
      (keydown.control.enter)="insertNewline($event)"
      (focusin)="onFocusIn()"
      (focusout)="onFocusOut()"
      [ngClass]="[
        inputCss,
        (invalid && showValidationErrorOrSubmitted) ||
        markAsInvalid ||
        (displayCounter && control?.value?.length > maxCounter)
          ? 'invalid'
          : '',
        errors ? 'error-section' : '',
        control.value ? 'filled' : '',
        disabled ? 'disabled' : ''
      ]"
      [formControl]="control"
      [placeholder]="attrPlaceholder"
      [name]="attrName"
      [attr.aria-invalid]="invalid && showValidationErrorOrSubmitted"
      [disabled]="disabled ? true : null"
      (blur)="onBlur()"></textarea>
    <div class="display-flex justify-content-space-between">
      <div
        *ngIf="displayCounter"
        class="placeholder counter"
        [ngClass]="control?.value?.length > maxCounter ? 'red3' : ''">
        [{{ control?.value?.length || 0 }}/{{ maxCounter }}]
      </div>
    </div>
    <app-input-error
      *ngIf="errorSection && errors"
      [errors]="errors"
      [visible]="markAsInvalid || (errors && showValidationErrorOrSubmitted)"></app-input-error>
  </div>
</div>

<ng-template #textAreaTooltipTemplate>
  <div class="custom-text-area-tooltip-content">
    <div class="body-small text-color gray11">ctr/cmd + enter: new line</div>
  </div>
</ng-template>
