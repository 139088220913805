<form [formGroup]="form" *ngIf="form">
  <div
    class="attachment-container display-flex flex-column grid-gap-8"
    [ngClass]="[labelRequired && isEmpty && showValidationError ? 'empty' : '']">
    <div class="attachment-content display-flex flex-column" [ngClass]="containerCss">
      <div class="display-flex align-items-center grid-gap-8 flex-1">
        <app-input-file
          class="flex-1"
          [formControl]="file"
          [label]="label"
          [labelRequired]="labelRequired"
          [attrDisable]="lockFields || uploadInProgress || fileUploadLoading"
          [inputCss]="inputFileCss"
          [uploadSelection]="true"
          [multiselect]="true"
          [acceptedFormats]="acceptedFormats"
          (clearEvent)="clearUploadedFile()"
          (uploadInProgress)="onUploadInProgress($event)"
          [isResident]="isResident"></app-input-file>
      </div>
      <div *ngIf="allowLink" class="display-flex flex-column grid-gap-8 flex-1">
        <div class="display-flex align-items-end grid-gap-4">
          <app-input
            class="flex-1"
            [containerCss]="'display-flex flex-column '"
            [contentCss]="linkContentCss"
            [attrPlaceholder]="'or paste a URL'"
            [formControl]="link"
            (keydownEnterEvent)="submitLink()"
            [attrAutocomplete]="false"
            [errorSection]="false"
            [attrDisable]="lockFields || uploadInProgress"></app-input>
        </div>
        <ng-container [ngTemplateOutlet]="errorTemplate"></ng-container>
      </div>
    </div>
  </div>
  <app-input-error
    *ngIf="labelRequired && isEmpty && showValidationError"
    [errors]="{ required: true }"
    [visible]="labelRequired && isEmpty && showValidationError"></app-input-error>
  <ng-container [ngTemplateOutlet]="errorTemplate"></ng-container>
</form>

<ng-template #errorTemplate>
  <app-input-error *ngIf="link.errors" [errors]="link.errors" [visible]="!link.pristine"></app-input-error>
</ng-template>
