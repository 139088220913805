import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequiredRatioType, RestapiService } from '@app/services/restapi.service';
import { FileSearchQuery, FileUploadModel, Upload } from '@shared/interfaces';
import { AuthService } from '@shared/services/auth.service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadsService {
  constructor(private restApiService: RestapiService, private authService: AuthService) {}

  uploadFile(file: File, requiredRatio?: RequiredRatioType, requiredRationMin?: RequiredRatioType): Observable<Upload> {
    return this.restApiService.upload<Upload>(`FileUploads/`, file, {}, requiredRatio, requiredRationMin);
  }

  uploadFileProgress(file: File): Observable<HttpEvent<any>> {
    return this.restApiService.uploadProgress(`FileUploads/`, file);
  }

  removeFile(fileId: number): Observable<boolean> {
    return this.restApiService.delete<boolean>(`FileUploads/${fileId}`);
  }

  search(query: FileSearchQuery) {
    return this.restApiService.post<FileUploadModel[]>(`FileUploads/search`, query);
  }

  download(fileUploadId: number) {
    // TODO refactor. Do not subscribe in services and in multiple called user actions
    this.search({ ids: [fileUploadId] }).subscribe(result => {
      if (result?.length) {
        const a = document.createElement('a');
        a.href = result[0].cloudUri;
        a.target = '_blank';
        a.download = 'true';
        document.body.append(a);
        a.click();
        a.remove();
      }
    });
  }

  downloadFile(fileUploadId: number) {
    return this.authService
      .getAccessToken()
      .pipe(
        tap(token => {
          const a = document.createElement('a');
          a.href = `${environment.apiUrl}/FileUploads/Download/${fileUploadId}?access_token=${token}`;
          a.target = '_parent';
          a.download = 'true';
          document.body.append(a);
          a.click();
          a.remove();
        })
      )
      .subscribe();
  }
}
