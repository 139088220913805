import { CdkDragDrop, CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, switchMap, take, tap } from 'rxjs';

import {
  InspectionType,
  ScoreWeightModes,
  ScoringModels,
} from '@main-application/inspections/models/rest-inspections-model.interface';
import { TemplateEditorBase } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-base/template-editor-base.component';
import {
  AreaModel,
  AreaPageModel,
  RepeatableArea,
} from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { ButtonType } from '@shared/enums/button-type';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { getNextNumberItemName } from '@shared/functions/get-next-number-item-name';
import { ConfirmationModalData } from '@shared/interfaces/modal-data';
import { TemplateManagerService } from '@template/components/templates-manager/template-manager.service';
import { RestTemplateAreaModel, RestTemplateModel } from '@template/models/rest-template-model.interface';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { DialogResult } from '@ui-components/modals/config/dialog-result.enum';
import { ModalsService } from '@ui-components/modals/modals.service';

import { TemplateEditorAreaComponent } from './template-editor-area/template-editor-area.component';
import { TemplateEditorDialogHeaderComponent } from './template-editor-header/template-editor-header.component';
import { TemplateEditorContent } from '../../models/content-god-object';
import { getInitialAreaData, scrollIfOutOfDialog } from '../../models/share-methods.function';
import { DndExpandableService } from '../../services/dnd-expandable.service';
import { CommonExpandWorker } from '../../services/expand-worker';
import { ScoringModelsService } from '../../services/scoring-models.service';
import { TemplateEditorDialogService } from '../../services/template-editor-dialog.service';

export type TemplateEditorDialogData = {
  templateId: number;
  templateHeader?: string;
  isGlobal?: boolean;
  isEditable: boolean;
  highLightHeader?: boolean;
};

export interface TemplateEditorDialogResult {
  inspectionTemplateId?: number;
  inspectionTemplateName?: string;
}

const TEMPLATE_RELOAD_ACTION = 'template-reload-action';

@UntilDestroy()
@Component({
  selector: 'app-template-editor-dialog',
  templateUrl: './template-editor-dialog.component.html',
  styleUrls: ['./template-editor-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorDialogComponent extends TemplateEditorBase implements OnInit, AfterViewInit {
  expandDuration = this.dndExpandableService.expandDurationMs;
  scrollSpeed = this.dndExpandableService.scrollSpeed;
  readonly ButtonType = ButtonType;
  readonly EIcon = EIcon;
  readonly Math = Math;
  readonly EColorPalette = EColorPalette;

  inspectionTemplateContent: TemplateEditorContent = new TemplateEditorContent();
  isBlockDraggable = false;
  isLoading$ = new BehaviorSubject<boolean>(true);
  uploadingXls = false;
  inspectionTemplate: RestTemplateModel;
  actionItemMenuOpen = false;

  isScoringParametersExist = false;
  expandWorker: CommonExpandWorker;
  actionInspectionMenuOpen = false;
  scoringForm: FormGroup;
  scoringModelItems = [
    { label: 'No Scoring', value: ScoringModels.None },
    { label: 'Maximum 100', value: ScoringModels.Maximum100 },
  ];
  scoreWeightItems = [];

  initFinished = false;
  displayedAreas: AreaModel[] = [];
  displayInterval: ReturnType<typeof setInterval>;
  chunkLength = 2;
  chunkIndex = 0;

  @ViewChild('fileUpload', { static: true }) fileUploader: ElementRef;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChildren(TemplateEditorAreaComponent) inspectionItems!: QueryList<TemplateEditorAreaComponent>;
  @ViewChild(TemplateEditorDialogHeaderComponent) header: TemplateEditorDialogHeaderComponent;
  @ViewChild('areasDropListGroup') areasDropListGroup!: CdkDropList;
  @Output() scoringChanged = new EventEmitter<ScoringModels>();

  constructor(
    public dialogRef: MatDialogRef<TemplateEditorDialogComponent, TemplateEditorDialogResult>,
    store: Store<{}>,
    protected cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private templateEditorService: TemplateEditorDialogService,
    private templateManagerService: TemplateManagerService,
    private scoringModelsService: ScoringModelsService,
    private modalsService: ModalsService,
    private dndExpandableService: DndExpandableService,
    snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateEditorDialogData
  ) {
    super(store, snackbarService);
  }

  public get isScoringEnabled(): boolean {
    return this.inspectionTemplate?.scoringModel !== ScoringModels.None;
  }
  ngOnInit(): void {
    this.onGetTemplate();

    this.dndExpandableService.dndAreaEvent$.pipe(untilDestroyed(this)).subscribe(e => {
      if (e === 'end') return;
      setTimeout(
        () => this.dndExpandableService.fixDndCache(this.areasDropListGroup._dropListRef),
        this.dndExpandableService.expandDuration
      );
    });

    this.dialogRef.backdropClick().subscribe(() => this.cancel());

    this.dialogRef
      .keydownEvents()
      .pipe(filter(event => event.key === 'Escape'))
      .subscribe(() => this.cancel());

    this.snackbarService.snackBarActions$
      .pipe(
        untilDestroyed(this),
        tap(({ action }) => {
          if (action === TEMPLATE_RELOAD_ACTION) {
            this.isLoading$.next(true);
            this.snackbarService.resetSnackbarActions();
            this.onGetTemplate();
          }
        })
      )
      .subscribe();

    this.scoringModelsService.scoringModel$.pipe(untilDestroyed(this)).subscribe(model => {
      this.cdr.detectChanges();
    });
  }

  onScoringModelChange(newModel: ScoringModels): void {
    this.scoringModelsService.setScoringModel(newModel);
  }

  ngAfterViewInit() {
    if (this.data.highLightHeader) {
      setTimeout(() => {
        this.header?.enterEditMode();
      }, 0);
    }
  }

  onGetTemplate(): void {
    (this.data.isGlobal
      ? this.templateManagerService.getTemplate(this.data.templateId)
      : this.templateEditorService.getTemplate(this.data.templateId)
    )
      .pipe(untilDestroyed(this))
      .subscribe(template => this.initTemplate(template));
  }

  initTemplate(template: RestTemplateModel) {
    if (template.inspectionType !== InspectionType.CustomInspection) {
      this.modalsService.openInfoModal({
        title: 'Sorry!',
        content: `${template.name} can’t be edited, please contact support if you need to adjust it.`,
        buttonText: 'OK',
      });
      this.cancel();
      return;
    }
    this.inspectionTemplate = template;

    this.isScoringParametersExist = this.inspectionTemplate.scoringModel > 0;

    this.scoringForm = this.fb.group({
      scoringModel: [this.inspectionTemplate?.scoringModel || ScoringModels.None],
    });

    this.scoringForm
      .get('scoringModel')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(value => this.scoringChange(value));

    this.inspectionTemplateContent.init(template);
    this.startDisplayInChunks();
    if (this.scoringForm.get('scoringModel').value === ScoringModels.Maximum100) {
      this.initScoringWeights();
    }
    this.scoringModelsService.combinedWeights$.pipe(untilDestroyed(this)).subscribe(customWeights => {
      const combinedWeights = [
        ...this.scoreWeightItems.filter(item => item.value !== 0),
        ...customWeights.map(weight => ({ label: `${weight}%`, value: weight })),
      ];
      this.scoreWeightItems = combinedWeights;
      this.cdr.markForCheck();
    });
    this.isLoading$.next(false);
    this.uploadingXls = false;
  }

  startDisplayInChunks(): void {
    this.displayNextChunk();
    this.displayInterval = setInterval(() => {
      if (this.chunkIndex * this.chunkLength < this.inspectionTemplateContent.areas.length) {
        this.displayNextChunk();
      } else {
        clearInterval(this.displayInterval);
        setTimeout(() => {
          this.initFinished = true;
        }, 500);
      }
    }, 500);
  }

  displayNextChunk(): void {
    const start = this.chunkIndex * this.chunkLength;
    const end = start + this.chunkLength;
    const nextChunk = this.inspectionTemplateContent.areas.slice(start, end);
    this.displayedAreas = [...this.displayedAreas, ...nextChunk];
    this.chunkIndex++;
    this.cdr.markForCheck();
  }

  initScoringWeights = () => {
    const standardWeights = [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];
    const otherWeights = new Set<number>();

    this.inspectionTemplateContent.areas.forEach((area: AreaModel) => {
      if (area.scoreWeight && !standardWeights.includes(area.scoreWeight)) {
        otherWeights.add(area.scoreWeight);
      }

      area.areaSurvey.pages.forEach((page: AreaPageModel) => {
        if (page.scoreWeight && !standardWeights.includes(page.scoreWeight)) {
          otherWeights.add(page.scoreWeight);
        }
      });
    });

    Array.from(otherWeights).forEach(weight => {
      this.scoringModelsService.addCustomWeight(weight);
    });
  };

  add(isParentless = false) {
    const title = getNextNumberItemName(
      isParentless ? 'Item ' : 'Area ',
      this.inspectionTemplateContent.areas,
      e => e.title
    );
    this.templateEditorService.itemFocused$.pipe(take(1)).subscribe(focusItem => {
      const area: RestTemplateAreaModel = {
        title,
        scoreWeightMode: ScoreWeightModes.Automatic,
        scoreWeight: 100,
        areaSurveyJson: JSON.stringify(getInitialAreaData(title, isParentless)),
        position: focusItem != null ? focusItem.position + 1 : this.inspectionTemplateContent.areas.length,
      } as RestTemplateAreaModel;

      this.addInternal(area);
    });
  }

  clone(area: AreaModel) {
    const { areaSurvey, ...props } = this.inspectionTemplateContent.getClonedArea(area);
    const clonedArea: RestTemplateAreaModel = {
      ...props,
      areaSurveyJson: JSON.stringify(areaSurvey),
      position: area.position + 1,
    } as RestTemplateAreaModel;

    this.addInternal(clonedArea);
  }

  private addInternal(area: RestTemplateAreaModel) {
    if (!this.checkEditRight()) {
      return;
    }

    area.inspectionTemplateId = this.inspectionTemplate.id;

    const tempId = Math.min(0, ...this.inspectionTemplateContent.areas.map(e => -e.id)) - 1;
    this.inspectionTemplateContent.addArea({ ...area, id: tempId });
    this.cdr.markForCheck();
    setTimeout(() => {
      const areaId = `template-area-${Math.abs(tempId)}`;
      const element = document.getElementById(areaId);
      if (element) {
        scrollIfOutOfDialog(element);
      }
    }, 1000);

    this.templateEditorService
      .updateAreasPosition(this.inspectionTemplateContent.areas, TEMPLATE_RELOAD_ACTION)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: responses => {
          responses.forEach(r => {
            if (r) {
              const [id, restArea] = r;
              this.inspectionTemplateContent.replaceTempAreaWithRestArea(tempId, restArea);
              this.cdr.markForCheck();
              setTimeout(() => {
                const item = this.inspectionItems.find(c => c.area.id === id);
                item?.enterEditMode();
                const element = (item as any)?.__ngContext__?.native as HTMLElement;
                if (element) scrollIfOutOfDialog(element);
              });
            }
          });
        },
        error: err => {
          this.inspectionTemplateContent.areas = this.inspectionTemplateContent.areas.filter(el => el.id !== tempId);
        },
      });
  }

  enterPressed(item: AreaModel) {
    if (!this.checkEditRight()) {
      return;
    }
    const index = this.inspectionTemplateContent.areas.findIndex(itemInner => itemInner.id === item.id);
    if (index === this.inspectionTemplateContent.areas.length - 1) {
      const isParentless = item.areaSurvey.pages.length == 1 && !item.areaSurvey.pages[0].pageType;
      this.add(!!isParentless);
    } else {
      const id = this.inspectionTemplateContent.areas[index + 1].id;
      const selectedItem = this.inspectionItems.find(c => c.area.id === id);
      selectedItem?.enterEditMode();

      const element = (selectedItem as any)?.__ngContext__?.native as HTMLElement;
      if (element) scrollIfOutOfDialog(element);
    }
  }

  trackBy(_index: number, item: AreaModel) {
    return item?.id;
  }

  dropDnd(event: CdkDragDrop<unknown, unknown, AreaModel>) {
    if (!this.checkEditRight()) {
      return;
    }
    this.dndExpandableService.endDnd(true);
    if (event.container != event.previousContainer) {
      return;
    }
    if (event.previousIndex == event.currentIndex) {
      return;
    }
    moveItemInArray(this.inspectionTemplateContent.areas, event.previousIndex, event.currentIndex);
    this.templateEditorService
      .updateAreasPosition(this.inspectionTemplateContent.areas, TEMPLATE_RELOAD_ACTION)
      .subscribe();
  }

  startDnd(event: CdkDragStart<AreaModel>) {
    if (!this.checkEditRight()) {
      return;
    }
    this.dndExpandableService.startDnd(event, true);
  }

  remove(item: AreaModel) {
    if (!this.checkEditRight()) {
      return;
    }
    this.templateEditorService.removeArea(item.id).subscribe();
    this.inspectionTemplateContent.removeArea(item.id);
    this.dndExpandableService.viewportZoneChanging.next(null);
  }

  repeatableChange([type, id]: [RepeatableArea, number]) {
    if (!this.checkEditRight()) {
      return;
    }
    let shouldBathroomRepeatable = false;
    let shouldBedroomRepeatable = false;
    let shouldHalfBathroomRepeatable = false;
    let shouldAnyBathroomRepeatable = false;

    const indexBathroom = this.inspectionTemplate.additionalBathroomInspectionTemplateAreaIds?.indexOf(id) ?? -1;
    const indexBedroom = this.inspectionTemplate.additionalBedroomInspectionTemplateAreaIds?.indexOf(id) ?? -1;
    const indexHalfBathroom =
      this.inspectionTemplate.additionalHalfBathRoomInspectionTemplateAreaIds?.indexOf(id) ?? -1;
    const indexAnyBathroom = this.inspectionTemplate.additionalAnyBathRoomInspectionTemplateAreaIds?.indexOf(id) ?? -1;

    switch (type) {
      case RepeatableArea.Bathroom:
        if (indexBathroom < 0) {
          shouldBathroomRepeatable = true;
        }
        break;
      case RepeatableArea.Bedroom:
        if (indexBedroom < 0) {
          shouldBedroomRepeatable = true;
        }
        break;
      case RepeatableArea.HalfBathroom:
        if (indexHalfBathroom < 0) {
          shouldHalfBathroomRepeatable = true;
        }
        break;
      case RepeatableArea.AnyBathRoom:
        if (indexAnyBathroom < 0) {
          shouldAnyBathroomRepeatable = true;
        }
        break;
    }
    const doCheck = (
      field:
        | 'additionalBedroomInspectionTemplateAreaIds'
        | 'additionalBathroomInspectionTemplateAreaIds'
        | 'additionalHalfBathRoomInspectionTemplateAreaIds'
        | 'additionalAnyBathRoomInspectionTemplateAreaIds',
      indexRepeatable: number,
      shouldRepeatable: boolean
    ) => {
      if (indexRepeatable >= 0 && !shouldRepeatable) {
        this.inspectionTemplate[field]?.splice(indexRepeatable, 1);
      }
      if (indexRepeatable < 0 && shouldRepeatable) {
        this.inspectionTemplate[field] ??= [];
        this.inspectionTemplate[field].push(id);
      }
    };
    doCheck('additionalBedroomInspectionTemplateAreaIds', indexBedroom, shouldBedroomRepeatable);
    doCheck('additionalBathroomInspectionTemplateAreaIds', indexBathroom, shouldBathroomRepeatable);
    doCheck('additionalHalfBathRoomInspectionTemplateAreaIds', indexHalfBathroom, shouldHalfBathroomRepeatable);
    doCheck('additionalAnyBathRoomInspectionTemplateAreaIds', indexAnyBathroom, shouldAnyBathroomRepeatable);

    this.templateEditorService.editTemplate(this.inspectionTemplate).subscribe();
  }

  areaChange(item: AreaModel) {
    if (!this.initFinished) return;
    if (!this.checkEditRight()) {
      return;
    }
    this.templateEditorService.updateArea(item).subscribe();
    this.inspectionTemplateContent.updateArea(item);
    this.dndExpandableService.viewportZoneChanging.next(null);
  }

  removeTemplate() {
    if (!this.checkEditRight()) {
      return;
    }
    const data: ConfirmationModalData = {};
    this.templateEditorService
      .openRemovalTemplateModal(this.inspectionTemplate, data)
      .afterClosed()
      .pipe(filter(result => result === DialogResult.Success))
      .subscribe(() => {
        this.templateEditorService.removeTemplate(this.inspectionTemplate).subscribe();
        this.dialogRef.close();
      });
  }

  headerChange(header: string) {
    if (!this.checkEditRight()) {
      return;
    }
    this.inspectionTemplate.name = header;
    this.updateCurrentTemplate();
  }

  descriptionChange(description: string) {
    if (!this.checkEditRight()) {
      return;
    }
    this.inspectionTemplate.description = description;
    this.templateEditorService.editTemplate(this.inspectionTemplate).subscribe();
  }

  residentSignatureChange() {
    if (!this.checkEditRight()) {
      return;
    }
    this.inspectionTemplate.residentSignatureRequired = !this.inspectionTemplate.residentSignatureRequired;
    this.templateEditorService.editTemplate(this.inspectionTemplate).subscribe();
  }
  inspectionScoringChange() {
    if (!this.checkEditRight()) {
      return;
    }
    if (this.inspectionTemplate.scoringModel === ScoringModels.None) {
      this.scoringForm.get('scoringModel').setValue(ScoringModels.Maximum100);
    } else {
      this.scoringForm.get('scoringModel').setValue(ScoringModels.None);
    }
  }

  scoringChange(value: any) {
    if (!this.checkEditRight()) {
      return;
    }
    const selectedScoringModel = value as ScoringModels;
    if (selectedScoringModel !== undefined && selectedScoringModel !== null) {
      this.inspectionTemplate.scoringModel = selectedScoringModel;
      this.templateEditorService.editTemplate(this.inspectionTemplate).subscribe();

      this.scoringModelsService.setScoringModel(selectedScoringModel);
    }
    this.cdr.detectChanges();
  }

  updateCurrentTemplate() {
    this.templateEditorService
      .editCustomInspectionTemplate(
        this.inspectionTemplate.id,
        {
          inspectionName: this.inspectionTemplate.name,
          isRequired: this.inspectionTemplate.customInspectionIsRequired,
        },
        TEMPLATE_RELOAD_ACTION
      )
      .subscribe();
  }

  cancel() {
    this.dialogRef.close();
  }

  checkEditRight(notify = true): boolean {
    if (!this.initFinished) return;
    return super.checkEditRight(this.data.isEditable, notify);
  }

  onMatDialogScroll(event: Event) {
    this.dndExpandableService.viewportZoneChanging.next(null);
  }

  downloadFile() {
    this.templateEditorService.downloadTemplateFile(this.inspectionTemplate.id).subscribe(data => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = this.inspectionTemplate.name + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  uploadFile() {
    this.fileUploader.nativeElement.value = '';
    this.fileUploader.nativeElement.click();
  }

  fileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadingXls = true;
    this.templateEditorService
      .uploadTemplateFile(this.inspectionTemplate.id, file)
      .pipe(
        tap(result => {
          if (result.errors.length > 0) {
            this.modalsService.openInfoModal({
              title: 'Awww shucks. Import failed 💩',
              content: result.errors[0].message ?? 'Your import failed',
            });
            this.uploadingXls = false;
          }
        }),
        filter(result => result.state === 'Success'),
        tap(() => {
          this.isLoading$.next(true);
          this.cdr.markForCheck();
        }),
        switchMap(() => this.templateEditorService.getTemplate(this.inspectionTemplate.id))
      )
      .subscribe(template => this.initTemplate(template));
  }
}
