<div class="radio-button-container display-flex flex-column">
  <label [ngClass]="[labelCss, labelRequired ? 'label-required' : '']" *ngIf="label">{{ label }}</label>
  <div class="radio-button-container-wrapper">
    <mat-radio-group [formControl]="control" [ngClass]="radioGroupClass" (change)="onChanged($event.value)">
      <mat-radio-button *ngFor="let item of items" [value]="item.value">
        <div class="body-small" [innerHTML]="item.label | escapeHtml"></div>
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <app-input-error
    *ngIf="errorSection && errors"
    [errors]="errors"
    [visible]="markAsInvalid || (errors && showValidationError)"></app-input-error>
</div>
