import {
  CDK_DRAG_PARENT,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragStart,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { includes } from 'lodash';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs';

import { ScoreWeightModes, ScoringModels } from '@main-application/inspections/models/rest-inspections-model.interface';
import { TemplateEditorBase } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-base/template-editor-base.component';
import { AreaHelper } from '@main-application/shared/template-editor-dialog/models/area.helper';
import {
  getUpdatedAreaModel,
  handleMaxLimitContentEditable,
  scrollIfOutOfDialog,
} from '@main-application/shared/template-editor-dialog/models/share-methods.function';
import {
  AreaModel,
  AreaPageModel,
  RepeatableArea,
  TemplateEditorDefaultValue,
  TemplateEditorInstructionsDefaultValue,
  TemplateEditorRatingGroup,
  TemplateMaxAreaAndItemLength,
} from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { DndExpandableService } from '@main-application/shared/template-editor-dialog/services/dnd-expandable.service';
import {
  AreaExpandWorker,
  CommonExpandWorker,
  WrapperExpandWorker,
} from '@main-application/shared/template-editor-dialog/services/expand-worker';
import { ScoringModelsService } from '@main-application/shared/template-editor-dialog/services/scoring-models.service';
import { SparePartsService } from '@main-application/shared/template-editor-dialog/services/spare-parts.service';
import { TemplateEditorDialogService } from '@main-application/shared/template-editor-dialog/services/template-editor-dialog.service';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { getNextNumberItemName } from '@shared/functions/get-next-number-item-name';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { TemplateEditorDialogItemComponent } from './template-editor-item/template-editor-item.component';
import { CustomWeightModalComponent } from '../../custom-weight-modal/custom-weight-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-template-editor-area',
  templateUrl: './template-editor-area.component.html',
  styleUrls: ['./template-editor-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorAreaComponent extends TemplateEditorBase implements OnInit, OnChanges, AfterViewInit {
  scrollSpeed = this.dndExpandableService.scrollSpeed;

  protected readonly TemplateEditorDefaultValue = TemplateEditorDefaultValue;
  public EIcon = EIcon;
  public EColorPalette = EColorPalette;
  protected readonly RepeatableArea = RepeatableArea;
  public sparePartsGroups$ = this.templateEditorDialogService.getSparePartsGroups();

  public editMode = false;
  public repeatableMenuOpen = false;
  public expandWorker: CommonExpandWorker;
  public actionAreaMenuOpen = false;
  public scoringParametersForm = this.fb.group({
    scoreWeightMode: [ScoreWeightModes.Automatic as ScoreWeightModes],
    scoreWeight: [100],
  });
  public scoreWeightItems = [];
  public previousValue = 100;
  public isDropdownOpen = false;
  public instructionsEditMode = false;
  protected TemplateEditorInstructionsDefaultValue = TemplateEditorInstructionsDefaultValue;
  public showInstructions = false;
  public instructions = '';
  public openSparePartsPopup = false;
  private _areaSubject$ = new BehaviorSubject<AreaModel>(null);

  @Input() isEditable: boolean;
  @Input() initFinished: boolean;
  @Output() remove = new EventEmitter<AreaModel>();
  @Output() clone = new EventEmitter<AreaModel>();
  @Output() openSpareParts = new EventEmitter<AreaModel>();
  @Output() areaChange = new EventEmitter<AreaModel>();
  @Output() enterPressed = new EventEmitter<AreaModel>();
  @Output() repeatableChange = new EventEmitter<[RepeatableArea, number]>();

  @Input() template: RestTemplateModel;
  _area: AreaModel;
  @Input() set area(area: AreaModel) {
    this._area = area;
    this._areaSubject$.next(area);
    if (this.isParentless && this.area.title != this.pages[0].title) {
      this.area.title = this.pages[0].title;
    }
  }

  get area(): AreaModel {
    return this._area;
  }

  get pages() {
    return this.area.areaSurvey.pages;
  }

  get isParentless() {
    return this.pages?.length === 1 && !this.pages[0].pageType;
  }

  @ViewChild('areaName', { read: ElementRef, static: false }) areaName: ElementRef<HTMLDivElement>;
  @ViewChild(CdkDragHandle, { static: true }) handle: CdkDragHandle;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  @ViewChildren(TemplateEditorDialogItemComponent) areaItems!: QueryList<TemplateEditorDialogItemComponent>;

  public sparePartsGroupsMerged$ = this._areaSubject$.pipe(
    map(area => area.areaSurvey?.sparePartsGroups ?? []),
    switchMap(areaSparePartsGroups =>
      this.sparePartsGroups$.pipe(
        map(groups =>
          groups.map(group => ({
            ...group,
            isActive: !!includes(areaSparePartsGroups, group.id),
          }))
        )
      )
    ),
    shareReplay()
  );
  constructor(
    store: Store<{}>,
    protected cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private templateEditorDialogService: TemplateEditorDialogService,
    private scoringModelsService: ScoringModelsService,
    private dndExpandableService: DndExpandableService,
    snackbarService: SnackbarService,
    private sparePartsService: SparePartsService,
    private dialog: MatDialog,
    @Optional() @Inject(CDK_DRAG_PARENT) public cdk
  ) {
    super(store, snackbarService);
  }

  ngOnInit(): void {
    this.expandWorker = this.isParentless
      ? new WrapperExpandWorker(this.cdr, this.dndExpandableService, this.snackbarService)
      : new AreaExpandWorker(this.cdr, this.dndExpandableService, this.snackbarService);
    this.expandWorker.init(this.template.inspectionTemplateAreas.length, null, this);

    const currentScoreWeight = this.scoringParametersForm.get('scoreWeight').value;
    if (currentScoreWeight !== undefined && currentScoreWeight !== null) {
      const existingItemIndex = this.scoreWeightItems.findIndex(item => item.value === currentScoreWeight);
      if (existingItemIndex === -1) {
        const updatedScoreWeightItems = [
          ...this.scoreWeightItems,
          { label: `${currentScoreWeight}%`, value: currentScoreWeight },
        ];
        this.previousValue = currentScoreWeight;

        updatedScoreWeightItems.sort((a, b) => {
          if (a.value === 100) return -1;
          if (b.value === 100) return 1;
          return b.value - a.value;
        });
        this.scoreWeightItems = updatedScoreWeightItems;
        this.cdr.markForCheck();
      }
    }

    this.scoringParametersForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((a, b) => a.scoreWeightMode === b.scoreWeightMode && a.scoreWeight === b.scoreWeight),
        filter(e => e.scoreWeightMode !== this.area.scoreWeightMode || e.scoreWeight !== this.area.scoreWeight)
      )
      .subscribe(fields => {
        if (fields.scoreWeight === 0) {
          const dialogRef = this.dialog.open(CustomWeightModalComponent, {
            width: '256px',
            data: { customWeight: 0 },
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result === 0) {
              this.areaChange.emit({ ...this.area, scoreWeight: this.previousValue });
              this.cdr.markForCheck();
            } else if (result !== undefined) {
              this.scoringModelsService.addCustomWeight(result);

              this.scoringParametersForm.patchValue({
                scoreWeight: result,
              });

              this.areaChange.emit({ ...this.area, scoreWeight: result });
              this.previousValue = result;
              this.cdr.markForCheck();
            } else {
              this.areaChange.emit({ ...this.area, scoreWeight: this.previousValue });
              this.cdr.markForCheck();
            }
          });
        } else {
          this.areaChange.emit({ ...this.area, ...fields });
        }
      });

    this.scoringModelsService.combinedWeights$.pipe(untilDestroyed(this)).subscribe(customWeights => {
      const combinedWeights = this.scoringModelsService.getCombinedWeights();
      this.scoreWeightItems = combinedWeights;
      this.cdr.markForCheck();
    });

    this.scoringModelsService.scoringModel$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
    });

    const parsedSurvey = this.area.areaSurvey;
    this.showInstructions = !!(parsedSurvey?.instructions?.trim() || parsedSurvey?.showInstructions);
    this.instructions = parsedSurvey?.instructions ?? '';
  }

  ngAfterViewInit() {
    this.cdk._handles.length = 1;
    this.cdk._handles._results = [this.handle];
    this.cdk._handles.changes.next();

    const scoreWeight = this.area.scoreWeight === 0 ? 100 : this.area.scoreWeight ?? 100;
    this.scoringParametersForm.patchValue({
      scoreWeightMode: this.area.scoreWeightMode ?? ScoreWeightModes.Automatic,
      scoreWeight: scoreWeight,
    });

    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.area) {
      if (this.template.scoringModel !== ScoringModels.Maximum100) {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Automatic,
          scoreWeight: 100,
        });
      } else if (this.area.scoreWeight === 100) {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Automatic,
          scoreWeight: 100,
        });
      } else if (this.template.scoringModel === ScoringModels.Maximum100) {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Manual,
          scoreWeight: this.area.scoreWeight !== null ? this.area.scoreWeight : 100,
        });
      } else {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Automatic,
          scoreWeight: 100,
        });
      }
    }
  }

  add() {
    if (!this.checkEditRight()) {
      return;
    }

    const title = this.isParentless ? 'Item 1' : getNextNumberItemName('Item ', this.pages, e => e.title);
    const name = AreaHelper.addItem(this.area, title);
    this.emitItemsChange();
    this.cdr.markForCheck();
    setTimeout(() => {
      const item = this.areaItems.find(c => c.name === name);
      item?.enterEditMode();

      const element = (item as any)?.__ngContext__?.native as HTMLElement;
      scrollIfOutOfDialog(element);
    }, 100);
  }

  emitItemsChange() {
    this.areaChange.emit(getUpdatedAreaModel(this.area));
    this.cdr.detectChanges();
  }

  trackBy(_index: number, item: AreaPageModel) {
    return item?.name;
  }

  dropDnd(event: CdkDragDrop<AreaModel, AreaModel, AreaPageModel>) {
    if (!this.checkEditRight()) {
      return;
    }
    this.dndExpandableService.endDnd(false);
    if (event.container != event.previousContainer) {
      transferArrayItem(
        event.previousContainer.data.areaSurvey.pages,
        event.container.data.areaSurvey.pages,
        event.previousIndex,
        event.currentIndex
      );
      this.areaChange.emit(getUpdatedAreaModel(event.container.data));
      this.areaChange.emit(getUpdatedAreaModel(event.previousContainer.data));
      return;
    }
    if (event.previousIndex == event.currentIndex) {
      return;
    }
    moveItemInArray(this.pages, event.previousIndex, event.currentIndex);
    this.emitItemsChange();
  }

  startDnd(event: CdkDragStart<AreaPageModel>) {
    if (!this.checkEditRight()) {
      return;
    }
    this.dndExpandableService.startDnd(event, false);
  }

  enterEditMode() {
    if (!this.checkEditRight() || this.editMode) {
      return;
    }
    this.templateEditorDialogService.focusItem(this.area);
    if (this.isParentless) {
      this.areaItems?.first?.enterEditMode();
      return;
    }
    this.editMode = true;
    this.cdr.detectChanges();
    const div = this.areaName.nativeElement;
    setTimeout(() => {
      const range = document.createRange();
      range.selectNodeContents(div);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0);
  }

  nameChanged($event: KeyboardEvent) {
    if (!this.checkEditRight()) {
      return;
    }
    handleMaxLimitContentEditable($event, this.areaName.nativeElement, TemplateMaxAreaAndItemLength);

    if ($event.key === 'Enter') {
      this.areaName.nativeElement.blur();
      this.enterPressed.emit(this.area);
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  get isScoringParametersExist() {
    return this.area.scoreWeightMode != null && typeof this.area.scoreWeightMode != 'undefined';
  }

  leaveEditMode(value: string) {
    value = value.trim();
    this.editMode = false;
    if (this.area.title !== value) {
      if (!value) {
        value = TemplateEditorDefaultValue;
        this.areaName.nativeElement.textContent = TemplateEditorDefaultValue;
      }
      const pages = this.area.areaSurvey.pages;
      this.areaChange.emit({
        ...this.area,
        title: value,
        areaSurvey: { ...this.area.areaSurvey, title: value, pages },
      });
    }

    this.templateEditorDialogService.leaveFocus(this.area.id);

    setTimeout(() => {
      const div = this.areaName.nativeElement;
      div.focus();
      div.blur();
    }, 1);
  }

  toggleRepeatable(event: MouseEvent, type: RepeatableArea) {
    if (!this.checkEditRight()) {
      return;
    }
    event.stopPropagation();
    this.repeatableChange.emit([type, this.area.id]);
    setTimeout(() => {
      this.matMenuTrigger.closeMenu();
      this.cdr.markForCheck();
    }, 250);
  }

  removeClick() {
    if (!this.checkEditRight()) {
      return;
    }
    this.remove.emit(this.area);
  }

  cloneClick() {
    if (!this.checkEditRight()) {
      return;
    }
    this.clone.emit(this.area);
  }

  removeItem(item: AreaPageModel) {
    if (!this.checkEditRight()) {
      return;
    }
    AreaHelper.removeItem(this.area, item.name);
    this.emitItemsChange();
  }

  cloneItem(item: AreaPageModel) {
    if (!this.checkEditRight()) {
      return;
    }
    const name = AreaHelper.cloneItem(this.area, item);
    this.emitItemsChange();

    setTimeout(() => {
      const item = this.areaItems.find(c => c.name === name);
      item?.enterEditMode();

      const element = (item as any)?.__ngContext__?.native as HTMLElement;
      scrollIfOutOfDialog(element);
    }, 100);
  }

  itemChange(item: AreaPageModel) {
    if (!this.checkEditRight()) {
      return;
    }
    AreaHelper.updateItem(this.area.areaSurvey, item);
    this.emitItemsChange();
  }

  addRatingGroup([item, group]: [item: AreaPageModel, group: TemplateEditorRatingGroup]) {
    if (!this.checkEditRight()) {
      return;
    }
    AreaHelper.addOrReplaceRatingGroup(this.area.areaSurvey, item, group);
    this.emitItemsChange();
  }

  addQuestion([item, defaultValue]: [AreaPageModel, boolean]) {
    if (!this.checkEditRight()) {
      return;
    }
    const id = AreaHelper.addQuestion(this.area.areaSurvey, item, '', defaultValue);
    this.emitItemsChange();
    setTimeout(() => {
      this.templateEditorDialogService.focusQuestion(id);
    });
  }

  enterPressedHandler(item: AreaPageModel) {
    if (!this.checkEditRight()) {
      return;
    }
    if (this.isParentless) {
      this.enterPressed.emit(this.area);
      return;
    }
    const index = this.pages.findIndex(itemInner => itemInner.name === item.name);
    if (index >= this.pages.length - 1) {
      this.add();
    } else {
      const selectedItem = this.areaItems[index + 1];
      selectedItem?.enterEditMode();
    }
  }

  checkEditRight(notify = true): boolean {
    if (!this.initFinished) return;
    return super.checkEditRight(this.isEditable, notify);
  }

  scoreWeightChange(value: any) {
    if (!this.checkEditRight()) {
      return;
    }
    const selectedScoreWeight = value as number;
    if (selectedScoreWeight !== undefined && selectedScoreWeight !== null) {
      this.area.scoreWeight = selectedScoreWeight;
      this.areaChange.emit({
        ...this.area,
        scoreWeight: this.area.scoreWeight,
      });
    }
  }

  toggleInstructions(showInstructions: boolean) {
    if (!this.initFinished) return;
    this.showInstructions = showInstructions;
    this.areaChange.emit({
      ...this.area,
      areaSurvey: { ...this.area.areaSurvey, showInstructions: this.showInstructions },
    });
    this.cdr.detectChanges();
  }

  enterInstructionsEditMode() {
    if (!this.checkEditRight() || this.instructionsEditMode) {
      return;
    }
    this.templateEditorDialogService.focusItem(this.area);
    this.instructionsEditMode = true;
    this.cdr.detectChanges();
  }

  leaveInstructionsEditMode(value: string) {
    value = value.trim();
    this.instructionsEditMode = false;

    this.areaChange.emit({
      ...this.area,
      areaSurvey: { ...this.area.areaSurvey, instructions: value },
    });
    this.cdr.detectChanges();
  }

  handleClickSparePartGroupItem(event: MouseEvent, id: number) {
    if (event?.shiftKey) event.stopPropagation();
    this.areaChange.emit(this.sparePartsService.useSetOrUnsetSparePartsGroupsArea({ parent: this._area, id }));
    this.cdr.detectChanges();
  }
  toggleExpand() {
    this.expandWorker.toggleExpand();
    this.cdr.detectChanges();
  }
}
