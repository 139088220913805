export const allFileExtensions = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/svg+xml',
  'image/tiff',
  'video/x-msvideo',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'video/x-matroska',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/html',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
];

export const imageVideoFileExtensions = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/svg+xml',
  'image/tiff',
  'image/heic',
  'image/heif',
  'video/x-msvideo',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/mov',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
  'video/x-matroska',
];

export const videoExtensions = [
  '.x-msvideo',
  '.mp4',
  '.mpeg',
  '.ogg',
  '.mov',
  '.webm',
  '.3gpp',
  '.3gpp2',
  '.x-matroska',
];
