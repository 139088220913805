import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { RestTimezone } from '@main-application/management/pages/system-configuration/components/date-time-configuration/model/timezone';
import { TimezoneEntityHelper } from '@main-application/management/pages/system-configuration/components/timezone-entity.helper';
import { AddAnswerReqI } from '@main-application/service/pages/canned-answer.interface';
import { CommonInMemmoryUpdatingService } from '@main-application/shared/services/common-in-memmory-updating.service';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { checkPermissionLevel } from '@shared/functions/check-permission-level.function';
import { filterToQueryParams } from '@shared/functions/filter-to-query-params.function';
import {
  RestTicketCategoryModel,
  RestTicketModel,
  RestTypedTicketAttachment,
  UpdateTypedAttachmentToEntityModel,
} from '@shared/interfaces/turnover.interface';
import { UserData } from '@shared/interfaces/user-data';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { CacheService } from './cache.service';

export class TicketFilter {
  assignedToMe?: boolean;
  includeCompleted?: boolean;
  startDate?: Date;
  propertyId?: number;
  propertyIds?: number[];
  portfolioId?: number;
  ticketType?: number;
  isCompleted?: number;
}

@Injectable({
  providedIn: 'root',
})
export class TicketsService extends CommonInMemmoryUpdatingService<RestTicketModel, TicketFilter> {
  constructor(
    private restApiService: RestapiService,
    private cacheService: CacheService,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  create(turnoverTicket: RestTicketModel, residentMode = false): Observable<RestTicketModel> {
    return this.restApiService.create<RestTicketModel>(`Tickets?residentTickets=${residentMode}`, turnoverTicket);
  }

  update(turnoverTicket: RestTicketModel): Observable<RestTicketModel> {
    return this.restApiService.update<RestTicketModel>(`Tickets/${turnoverTicket.id}`, turnoverTicket);
  }

  protected deleteInternal(ticketId: number): Observable<void> {
    return this.restApiService.delete(`Tickets/${ticketId}`);
  }

  residentList(): Observable<RestTicketModel[]> {
    return this.restApiService.getData<RestTicketModel[]>(`Tickets?residentTickets=${true}`);
  }

  protected getListInternal(filter: any): Observable<RestTicketModel[]> {
    const filterString = filterToQueryParams(filter);
    return this.restApiService.getData<RestTicketModel[]>(
      `Tickets${filterString}`,
      TicketsService.fixTimezoneForRestTicketList
    );
  }

  rate(ticketId: number, clientFeedbackRating: number): Observable<RestTicketModel[]> {
    return this.restApiService.post<RestTicketModel[]>(`Tickets/submitfeedback`, {
      ticketId: ticketId,
      clientFeedbackRating: clientFeedbackRating,
    });
  }

  getTicket(ticketId: number): Observable<RestTicketModel> {
    return this.restApiService.getData<RestTicketModel>(
      `Tickets/${ticketId}`,
      TicketsService.fixTimezoneForRestTicketModel
    );
  }

  getTicketAttachment(ticketId: number): Observable<RestTypedTicketAttachment[]> {
    return this.restApiService.getData<RestTypedTicketAttachment[]>(`Tickets/${ticketId}/Attachments`);
  }

  setAttachment(attachmentToEntity: UpdateTypedAttachmentToEntityModel): Observable<RestTypedTicketAttachment> {
    return this.restApiService.create<RestTypedTicketAttachment>(
      `Tickets/${attachmentToEntity.ticketId}/Attachments`,
      attachmentToEntity
    );
  }

  getTicketsByTurnover(turnoverId: number): Observable<RestTicketModel[]> {
    return this.restApiService.getData<RestTicketModel[]>(
      `Tickets/ByTurnoverId/${turnoverId}`,
      TicketsService.fixTimezoneForRestTicketList
    );
  }

  public static fixTimezoneForRestTicketList(data: RestTicketModel[], timezone: RestTimezone): RestTicketModel[] {
    if (!data) return [];
    data.forEach(e => TimezoneEntityHelper.fixTimezoneForRestTicketDataModel(e, timezone));
    return data;
  }

  public static fixTimezoneForRestTicketModel(data: RestTicketModel, timezone: RestTimezone): RestTicketModel {
    return TimezoneEntityHelper.fixTimezoneForRestTicketDataModel(data, timezone);
  }

  getTicketsByAssigneeId(assigneeId: number): Observable<RestTicketModel[]> {
    return this.restApiService.getData<RestTicketModel[]>(`Tickets/ByAssigneeId/${assigneeId}`);
  }

  getTicketsByPropertyId(propertyId: number): Observable<RestTicketModel[]> {
    return this.restApiService.getData<RestTicketModel[]>(`Tickets/ByPropertyId/${propertyId}`);
  }

  deleteTurnoverAttachment(ticketId: number, attachmentId: number): Observable<boolean> {
    return this.restApiService.delete(`Tickets/${ticketId}/Attachments/${attachmentId}`);
  }

  getCashedCategories() {
    return this.cacheService.getCachedInfo('getCategories', 0, () => this.getCategories());
  }

  checkPermissionToEdit(userData: UserData, ticket?: RestTicketModel, notify = false): boolean {
    const result =
      ticket?.createdById === userData.id ||
      checkPermissionLevel(userData.permissionLevel, PermissionLevelType.Tickets_EditAll) ||
      (ticket?.assigneeId === userData?.id &&
        checkPermissionLevel(userData.permissionLevel, PermissionLevelType.Tickets_Edit));

    if (notify && !result) {
      this.snackbarService.warning('Tickets_EditAll permission required');
    }

    return result;
  }

  private getCategories() {
    return this.restApiService.getData<RestTicketCategoryModel[]>(`TicketPickupList`);
  }

  convertToSeconds(timeString: string): number {
    const [days, hours, minutes] = timeString.split('::').map(Number);
    return days * 86400 + hours * 3600 + minutes * 60;
  }

  convertToTimeSpent(seconds: number): string {
    const days = Math.floor(seconds / 86400);
    seconds %= 86400;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    return `${days}::${hours}::${minutes}`;
  }

  formatCurrency(value: number): string {
    if (!value || value === null) return null;
    const parts = value.toString().split('.');
    const wholePart = parts[0];
    const fractionalPart = parts.length > 1 ? '.' + parts[1] : '';
    return '$' + wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + fractionalPart;
  }

  parseCurrency(value: string): number {
    if (!value || value === null || value === '0') return null;
    return parseFloat(value.replace(/[^0-9.-]+/g, ''));
  }

  // canned answers
  getAnswers(): Observable<any> {
    return this.restApiService.getData<any>(`CannedAnswer`, TicketsService.fixTimezoneForRestTicketModel);
  }

  addAnswer(body: AddAnswerReqI): Observable<void> {
    return this.restApiService.create('CannedAnswer', body);
  }

  editAnswer(text: string, id: number): Observable<void> {
    return this.restApiService.update(`CannedAnswer/${id}`, { text });
  }

  deleteAnswer(id: number): Observable<void> {
    return this.restApiService.delete(`CannedAnswer/${id}`);
  }
}
