import { DatePipe } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatCalendar,
  MatCalendarCellCssClasses,
} from '@angular/material/datepicker';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, of, startWith } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { CalendarHeaderComponent } from '@app/modules/ui-components/components/calendar-header/calendar-header.component';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { DestroyService } from '@shared/services/destroy.service';
import { LocalCacheService } from '@shared/services/local-cache.service';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import { skipEqual$ } from '@shared/utils/rxjs/skip-equal.rxjs.util';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

import { SnackbarService } from '../../components/customized-snackbar/snackbar.service';

@UntilDestroy()
@Component({
  selector: 'app-date-dropdown',
  templateUrl: './date-dropdown.component.html',
  styleUrls: ['./date-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    DestroyService,
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class DateDropdownComponent
  extends CustomControlAbstract<Date>
  implements OnInit, AfterContentChecked, ControlValueAccessor, AfterViewInit
{
  maskControl = new UntypedFormControl('');
  invalid = false;
  isDisabled = false;
  errors: ValidationErrors;
  dateRangeConfig: DateRange<Date>;
  header = CalendarHeaderComponent;
  isOpened = false;

  @ViewChild(MatCalendar) calendar: MatCalendar<Date>;

  @Input() label = '';
  @Input() labelTooltip: string;
  @Input() labelRequired: boolean;
  @Input() labelInside = false;
  private readonly labelInsideDefaultCss = 'nowrap pre-title';
  private readonly labelOutsideDefaultCss = 'nowrap body-small-bold';
  _labelCss = '';
  @Input() set labelCss(value: string) {
    this._labelCss = value;
  }

  get labelCss(): string {
    if (this._labelCss) return this._labelCss;
    return this.labelInside ? this.labelInsideDefaultCss : this.labelOutsideDefaultCss;
  }

  @Input() attrPlaceholder = '';
  @Input() buttonCss = 'date-dropdown-button';
  @Input() containerCss = 'display-flex align-items-center';
  @Input() valueCss = 'body-small text-color dark';
  @Input() placeholderCss = 'placeholder';
  @Input() errorSection = true;
  @Input() markAsInvalid = false;
  @Input() displayMask = 'MM/dd/yy';
  @Input() attrClickableDisable = false;
  @Input() filterGroupKey?: string;
  @Input() max: Date;
  @Input() min: Date;
  @Input() clearable = false;
  @Input() bottomContent = false;
  @Input() editable = false;
  @Input() isFullWidth = false;
  @Input() errorDisabledText: string;

  @Input() set attrDisable(attrDisable: boolean) {
    if (attrDisable) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
    this.isDisabled = attrDisable;
    this.cdr.markForCheck();
  }

  @Output() disabledClickEvent = new EventEmitter();
  @Output() selectedRangeEvent: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() clearDate = new EventEmitter<void>();

  _labelElement: ElementRef<HTMLElement>;
  _labelWidth$ = new BehaviorSubject<number>(null);
  labelWidth$ = this._labelWidth$.asObservable();
  labelWidthN: number;
  labelGroupWidth$: Observable<string>;
  hideBottomContent = false;

  @ViewChild('dateMenuTrigger') dateMenuTrigger: MatMenuTrigger;

  @ViewChild('labelElement', { static: false }) set content(value: ElementRef<HTMLElement>) {
    if (!value) return;
    this._labelElement = value;

    const labelWidth = value?.nativeElement?.clientWidth;
    labelWidth && this._labelWidth$.next(labelWidth);

    this.cdr.detectChanges();
  }

  private readonly today = this.timezoneService.getCurrentDateOnly();

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective,
    private timezoneService: TimezoneService,
    private snackbarService: SnackbarService,
    private cache: LocalCacheService,
    private destroy$: DestroyService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngOnInit(): void {
    this.initControlBase();
  }

  ngAfterViewInit() {
    if (this.control.value) {
      this.calendar.activeDate = this.control.value;
      this.dateRangeConfig = new DateRange(this.control.value, this.control.value);
      this.cdr.markForCheck();
    }
    this.control.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(value => {
      if (this.attrClickableDisable && this.isOpened) {
        this.disabledClickEvent.emit();
        this.dateMenuTrigger.closeMenu();
      } else {
        this.dateMenuTrigger.closeMenu();
        this.onTouched();
      }
      this.cdr.markForCheck();
    });

    this.initLabelWidth();
  }

  ngAfterContentChecked() {
    if (!this.labelInside || !this._labelElement) return;

    const labelWidth = this._labelElement?.nativeElement?.clientWidth;
    labelWidth && this._labelWidth$.next(labelWidth);
    this.cdr.detectChanges();
  }

  valueTyped(value: string) {
    if (value) {
      const split = value.split('/');
      if (split[2] && split[2].length === 2) {
        split[2] = '20' + split[2];
      }
      const preparedValue = split.join('/');
      const dt = moment(preparedValue, 'MM/DD/YYYY');
      if (dt.isValid()) {
        this.setValue(dt.toDate());
      } else {
        this.setValue(null);
        this.snackbarService.error(`Invalid date: ${value}`);
      }
    } else {
      this.setValue(null);
    }
  }

  updateCursor(input: HTMLInputElement) {
    setTimeout(() => {
      input.selectionStart = input.value.length ?? 0;
    });
  }

  clickEvent($event: MouseEvent) {
    if (!this.isDisabled) return;

    this.disabledClickEvent.emit($event);
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      if (date.getTime() === this.today.getTime()) {
        return 'special-date-today';
      }
      return;
    };
  }

  onSelect(date: Date) {
    if (this.dateRangeConfig?.start && date > this.dateRangeConfig.start && !this.dateRangeConfig.end) {
      this.dateRangeConfig = new DateRange(this.dateRangeConfig.start, date);
    } else {
      this.dateRangeConfig = new DateRange(date, null);
    }
  }

  onSelectSingleDate(date: Date) {
    this.hideBottomContent = true;

    this.dateRangeConfig = new DateRange(date, date);
    this.setValue(date);
    setTimeout(() => {
      this.hideBottomContent = false;
    });
  }

  selectRange() {
    if (this.control.value) {
      this.selectedRangeEvent.emit(this.control.value);
    }
    this.cdr.detectChanges();
  }

  writeValue(value: Date): void {
    this.maskControl.setValue(value ? new DatePipe(this.locale).transform(value, this.displayMask) : '');
    if (value) {
      this.dateRangeConfig = new DateRange(value, value);
      if (this.calendar) {
        this.calendar.activeDate = value;
      }
    }
  }

  setValue(date: Date) {
    if (this.ngControl.control.disabled) {
      return;
    }
    this.writeValue(date);
    if (this.control.value?.getTime() !== date?.getTime()) {
      this.onChanged(date);
      this.onTouched();
    }
  }

  dateToggleMenu(menuExpanded: boolean) {
    if (menuExpanded) {
      this.isOpened = true;
    }
    super.toggleMenu(menuExpanded);
  }

  openDateMenu(event: MouseEvent) {
    event.preventDefault();
    if (this.errorDisabledText) {
      this.snackbarService.error(this.errorDisabledText);
      return;
    }
    setTimeout(() => {
      this.dateMenuTrigger.openMenu();
    }, 1);
  }

  clear(event) {
    event.stopPropagation();
    this.onSelectSingleDate(null);
    this.cdr.markForCheck();
  }

  private initLabelWidth(): void {
    const labelWidth$ = this.labelWidth$.pipe(
      filterNullish$(false),
      skipEqual$(),
      startWith(0),
      tap(currentLabelWidth => {
        if (this.filterGroupKey) {
          const groupOffset = this.cache.get(this.filterGroupKey);
          if (groupOffset < currentLabelWidth) {
            this.cache.set(this.filterGroupKey, currentLabelWidth, {
              lsKey: this.filterGroupKey,
            });
          }
        }
      })
    );

    const labelGroupWidth$ = this.filterGroupKey
      ? this.cache
          .get$(this.filterGroupKey, this.destroy$, {
            initialState: 0,
            lsKey: this.filterGroupKey,
          })
          .pipe(skipEqual$())
      : of(null);

    this.labelGroupWidth$ = combineLatest([labelWidth$, labelGroupWidth$]).pipe(
      map(([labelWidth, groupWidth]) => Math.max(labelWidth, groupWidth)),
      filterNullish$(false),
      map(width => (width ? width + 'px' : 'initial')),
      skipEqual$()
    );
  }

  onClearDate(): void {
    this.clearDate.emit();
  }
}
