import { CDK_DRAG_PARENT, CdkDragHandle } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { find, includes, isEmpty } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';

import { PackageService } from '@app/services/package.service';
import { isDef } from '@app/utils/def';
import { ScoringModels } from '@main-application/inspections/models/rest-inspections-model.interface';
import { TemplateEditorBase } from '@main-application/shared/template-editor-dialog/components/template-editor-dialog/template-editor-base/template-editor-base.component';
import { handleMaxLimitContentEditable } from '@main-application/shared/template-editor-dialog/models/share-methods.function';
import {
  AreaModel,
  AreaPageElementQuestionModel,
  AreaPageElementRatingGroupModel,
  AreaPageModel,
  CompulsoryItems,
  TemplateEditorDefaultValue,
  TemplateEditorInstructionsDefaultValue,
  TemplateEditorRatingGroup,
  TemplateMaxAreaAndItemLength,
} from '@main-application/shared/template-editor-dialog/models/template-editor-model';
import { DndExpandableService } from '@main-application/shared/template-editor-dialog/services/dnd-expandable.service';
import {
  AreaExpandWorker,
  CommonExpandWorker,
  ItemExpandWorker,
} from '@main-application/shared/template-editor-dialog/services/expand-worker';
import { ScoringModelsService } from '@main-application/shared/template-editor-dialog/services/scoring-models.service';
import { SparePartsService } from '@main-application/shared/template-editor-dialog/services/spare-parts.service';
import { TemplateEditorDialogService } from '@main-application/shared/template-editor-dialog/services/template-editor-dialog.service';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { PackageType } from '@shared/enums/package-type';
import { PluralizationPipe } from '@shared/pipes/pluralization.pipe';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

import { ScoreWeightModes } from './../../../../../../inspections/models/rest-inspections-model.interface';
import { CustomWeightModalComponent } from '../../../custom-weight-modal/custom-weight-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-template-editor-item',
  templateUrl: './template-editor-item.component.html',
  styleUrls: ['./template-editor-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateEditorDialogItemComponent extends TemplateEditorBase implements OnInit, OnChanges, AfterViewInit {
  public EIcon = EIcon;
  public EColorPalette = EColorPalette;
  protected readonly TemplateEditorDefaultValue = TemplateEditorDefaultValue;
  protected readonly TemplateEditorInstructionsDefaultValue = TemplateEditorInstructionsDefaultValue;
  public editMode = false;
  public instructionsEditMode = false;
  public ratingGroups$ = this.templateEditorService.getRatingGroups();
  public openRatingPopup = false;
  public openSparePartsPopup = false;
  public actionItemMenuOpen = false;
  public timeAndMaterialForm = this.fb.group({
    timeCost: [null as number],
    materials: [null as number],
    laborCost: [null as number],
  });
  public scoringParametersForm = this.fb.group({
    scoreWeightMode: [ScoreWeightModes.Automatic as ScoreWeightModes],
    scoreWeight: [100],
  });
  public scoreWeightItems = [];
  public previousValue = 100;
  public isDropdownOpen = false;
  public expandWorker: CommonExpandWorker;
  private _sparePartsGroups$ = this.templateEditorService.getSparePartsGroups();
  private _item: AreaPageModel;
  private _itemSubject$ = new BehaviorSubject<AreaPageModel>(null);

  @Output() remove = new EventEmitter<AreaPageModel>();
  @Output() clone = new EventEmitter<AreaPageModel>();
  @Output() itemChange = new EventEmitter<AreaPageModel>();
  @Output() enterPressed = new EventEmitter<AreaPageModel>();
  @Output() ratingGroupAdd = new EventEmitter<[AreaPageModel, TemplateEditorRatingGroup]>();
  @Output() questionAdd = new EventEmitter<[AreaPageModel, boolean]>();
  @Input() isEditable: boolean;

  @Input() parent: AreaModel;
  @Input() grandParent: RestTemplateModel;
  @Input() isParentless = false;

  @Input() set item(areaItem: AreaPageModel) {
    this._item = areaItem;
    this._itemSubject$.next(areaItem);
  }
  get item(): AreaPageModel {
    return this._item;
  }

  get isSparePartsGroupButtonActive(): boolean {
    return this.sparePartsService.isSparePartsGroupButtonActive({ parent: this.parent, item: this.item });
  }

  get isUseSameForParentSparePartsButtonActive(): boolean {
    return this.sparePartsService.isUseSameForParentSparePartsButtonActive({ parent: this.parent, item: this.item });
  }

  get isDoNotUseSparePartsButtonActive(): boolean {
    return this.sparePartsService.isDoNotUseSparePartsButtonActive({ parent: this.parent, item: this.item });
  }
  @ViewChild('itemName', { read: ElementRef, static: false }) itemName: ElementRef<HTMLDivElement>;
  // @ViewChild('itemInstructions', { read: ElementRef, static: false }) itemInstructions: ElementRef<HTMLDivElement>;
  @ViewChild(CdkDragHandle, { static: true }) handle: CdkDragHandle;

  @HostBinding('class.is-parentless') get parentlessClass() {
    return this.isParentless;
  }

  public inspectionProPackageEnabled$ = this.isInspectionProPackageEnabled();
  public checkInspectionProPackageAndNotify$ = this.isInspectionProPackageEnabled(true);

  public sparePartsGroupsPageMerged$ = this._itemSubject$.pipe(
    switchMap(areaItem =>
      this._sparePartsGroups$.pipe(
        map(groups => {
          const areaSparePartsGroups = areaItem?.sparePartsGroups ?? [];
          if (this.isUseSameForParentSparePartsButtonActive) {
            return areaSparePartsGroups.map(groupId => ({
              ...find(groups, { id: groupId }),
              isActive: true,
            }));
          } else {
            return groups.map(group => ({
              ...group,
              isActive: !!includes(areaSparePartsGroups, group.id),
            }));
          }
        })
      )
    ),
    shareReplay()
  );

  constructor(
    store: Store<{}>,
    snackbarService: SnackbarService,
    protected cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    @Optional() @Inject(CDK_DRAG_PARENT) public cdk,
    private templateEditorService: TemplateEditorDialogService,
    private scoringModelsService: ScoringModelsService,
    private dndExpandableService: DndExpandableService,
    private sparePartsService: SparePartsService,
    private packageService: PackageService,
    private pluralizationPipe: PluralizationPipe,
    private dialog: MatDialog
  ) {
    super(store, snackbarService);
  }

  ngOnInit() {
    this.timeAndMaterialForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(
          (a, b) => a.laborCost == b.laborCost && a.materials == b.materials && a.timeCost == b.timeCost
        ),
        filter(
          e =>
            e.laborCost != this.item.laborCost || e.materials != this.item.materials || e.timeCost != this.item.timeCost
        )
      )
      .subscribe(fields => {
        this.checkInspectionProPackageAndNotify$.subscribe(packageEnabled => {
          if (packageEnabled) {
            this.itemChange.emit({ ...this.item, ...fields });
          }
        });
      });

    const currentScoreWeight = this.scoringParametersForm.get('scoreWeight').value;
    if (currentScoreWeight !== undefined && currentScoreWeight !== null) {
      const existingItemIndex = this.scoreWeightItems.findIndex(item => item.value === currentScoreWeight);
      if (existingItemIndex === -1) {
        const updatedScoreWeightItems = [
          ...this.scoreWeightItems,
          { label: `${currentScoreWeight}%`, value: currentScoreWeight },
        ];
        this.previousValue = currentScoreWeight;

        updatedScoreWeightItems.sort((a, b) => {
          if (a.value === 100) return -1;
          if (b.value === 100) return 1;
          return b.value - a.value;
        });
        this.scoreWeightItems = updatedScoreWeightItems;
        this.cdr.markForCheck();
      }
    }

    this.scoringParametersForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged((a, b) => a.scoreWeightMode === b.scoreWeightMode && a.scoreWeight === b.scoreWeight),
        filter(e => e.scoreWeightMode !== this.item.scoreWeightMode || e.scoreWeight !== this.item.scoreWeight)
      )
      .subscribe(fields => {
        if (fields.scoreWeight === 0) {
          const dialogRef = this.dialog.open(CustomWeightModalComponent, {
            width: '256px',
            data: { customWeight: 0 },
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result === 0) {
              this.itemChange.emit({ ...this.item, scoreWeight: this.previousValue });
              this.cdr.markForCheck();
            } else if (result !== undefined) {
              this.scoringModelsService.addCustomWeight(result);

              this.scoringParametersForm.patchValue({
                scoreWeight: result,
              });

              this.itemChange.emit({ ...this.item, scoreWeight: result });
              this.previousValue = result;
              this.cdr.markForCheck();
            } else {
              this.itemChange.emit({ ...this.item, scoreWeight: this.previousValue });
              this.cdr.markForCheck();
            }
          });
        } else {
          this.itemChange.emit({ ...this.item, ...fields });
        }
      });

    if (this.isParentless) {
      this.expandWorker = new AreaExpandWorker(this.cdr, this.dndExpandableService, this.snackbarService);
      this.expandWorker.init(this.grandParent.inspectionTemplateAreas.length, null, this);
    } else {
      this.expandWorker = new ItemExpandWorker(this.cdr, this.dndExpandableService, this.snackbarService);
      this.expandWorker.init(this.item.elements.length, this.parent.id, this);
    }

    this.scoringModelsService.combinedWeights$.pipe(untilDestroyed(this)).subscribe(customWeights => {
      const combinedWeights = this.scoringModelsService.getCombinedWeights();
      this.scoreWeightItems = combinedWeights;
      this.cdr.markForCheck();
    });

    this.scoringModelsService.scoringModel$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    if (this.handle) {
      this.cdk._handles.length = 1;
      this.cdk._handles._results = [this.handle];
      this.cdk._handles.changes.next();
    }

    const scoreWeight = this.item.scoreWeight === 0 ? 100 : this.item.scoreWeight ?? 100;
    this.scoringParametersForm.patchValue({
      scoreWeightMode: this.item.scoreWeightMode ?? ScoreWeightModes.Automatic,
      scoreWeight: scoreWeight,
    });

    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.timeAndMaterialForm.patchValue({
        laborCost: this.item.laborCost,
        materials: this.item.materials,
        timeCost: this.item.timeCost,
      });

      if (this.grandParent.scoringModel !== ScoringModels.Maximum100) {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Automatic,
          scoreWeight: 100,
        });
      } else if (this.item.scoreWeight === 100) {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Automatic,
          scoreWeight: 100,
        });
      } else if (this.grandParent.scoringModel === ScoringModels.Maximum100) {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Manual,
          scoreWeight: this.item.scoreWeight !== null ? this.item.scoreWeight : 100,
        });
      } else {
        this.scoringParametersForm.patchValue({
          scoreWeightMode: ScoreWeightModes.Automatic,
          scoreWeight: 100,
        });
      }
    }
  }

  get name() {
    return this.item.name;
  }

  get ratingGroup() {
    const item = this.item.elements?.find(
      element => element.type === 'propertystate'
    ) as AreaPageElementRatingGroupModel;
    return item;
  }

  get questions() {
    const items = this.item.elements?.filter(element => element.type === 'boolean') as AreaPageElementQuestionModel[];
    return items;
  }

  get isTAndMExist() {
    return this.item.timeCost != null && typeof this.item.timeCost != 'undefined';
  }

  get isScoringParametersExist() {
    return this.item.scoreWeightMode != null && typeof this.item.scoreWeightMode != 'undefined';
  }

  isInspectionProPackageEnabled(notify = false): Observable<boolean> {
    return combineLatest([
      this.packageService.checkPackageAvailable(PackageType.InspectionsPro),
      this.packageService.checkPackageTrialDaysLeft(PackageType.InspectionsPro),
    ]).pipe(
      untilDestroyed(this),
      map(([packageAvailable, trial]) => {
        if (trial.trialState > 0) {
          notify &&
            this.snackbarService.info(
              `Your trial ends in ${trial.trialState} ${this.pluralizationPipe.transform('day', trial.trialState)}`
            );
        }
        if (!packageAvailable && (trial.trialState < 0 || !isDef(trial.trialState))) {
          notify &&
            this.snackbarService.info(
              '<a href="mailto:sales@gopropup.com?subject=Interested%20in%20Inspection%20Pro%20package!">Contact us</a> for a free 90-day trial of Service!'
            );
        }
        return packageAvailable;
      }),
      shareReplay()
    );
  }

  trackBy(_index: number, question: AreaPageElementQuestionModel) {
    return question?.guid;
  }

  ratingGroupChange(ratingGroup: AreaPageElementRatingGroupModel) {
    const commentNotCompulsory = ratingGroup.items.some(e => !e.item.onSelect.includes('commentRequired'));
    const photoNotCompulsory = ratingGroup.items.some(e => !e.item.onSelect.includes('photoRequired'));
    const item = { ...this.item };
    if (commentNotCompulsory) {
      const index = item.compulsoryItems.indexOf('commentRequired');
      if (index >= 0) {
        item.compulsoryItems.splice(index, 1);
      }
    }
    if (photoNotCompulsory) {
      const index = item.compulsoryItems.indexOf('photoRequired');
      if (index >= 0) {
        item.compulsoryItems.splice(index, 1);
      }
    }
    this.itemChange.emit({
      ...item,
      elements: item.elements.map(element => {
        if (element.type === 'propertystate') {
          return ratingGroup;
        } else {
          return element;
        }
      }),
    });

    if (this.checkEditRight(false)) {
      this.templateEditorService.updateRatingGroup(ratingGroup).subscribe();
    }
  }

  questionChange(question: AreaPageElementQuestionModel) {
    this.itemChange.emit({
      ...this.item,
      elements: this.item.elements.map(element => {
        if (element.guid === question.guid) {
          return question;
        } else {
          return element;
        }
      }),
    });
  }

  questionRemove(question: AreaPageElementQuestionModel) {
    this.itemChange.emit({
      ...this.item,
      elements: this.item.elements.filter(element => element.guid !== question.guid),
    });
  }

  enterEditMode() {
    if (!this.checkEditRight() || this.editMode) {
      return;
    }
    this.templateEditorService.focusItem(this.parent);
    this.editMode = true;
    this.cdr.detectChanges();
    const div = this.itemName.nativeElement;
    setTimeout(() => {
      const range = document.createRange();
      range.selectNodeContents(div);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 0);
  }

  nameChanged($event: KeyboardEvent) {
    if (!this.checkEditRight()) {
      return;
    }
    handleMaxLimitContentEditable($event, this.itemName.nativeElement, TemplateMaxAreaAndItemLength);
    if ($event.key === 'Enter') {
      this.itemName.nativeElement.blur();
      this.enterPressed.emit(this.item);
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  leaveEditMode(value: string) {
    value = value.trim();
    this.editMode = false;
    if (this.item.title !== value) {
      if (!value) {
        value = TemplateEditorDefaultValue;
        this.itemName.nativeElement.textContent = TemplateEditorDefaultValue;
      }
      this.itemChange.emit({ ...this.item, title: value });
    }

    setTimeout(() => {
      const div = this.itemName.nativeElement;
      div.focus();
      div.blur();
    }, 1);
  }

  enterInstructionsEditMode() {
    if (!this.checkEditRight() || this.instructionsEditMode) {
      return;
    }
    this.templateEditorService.focusItem(this.parent);
    this.instructionsEditMode = true;
    this.cdr.detectChanges();
  }

  leaveInstructionsEditMode(value: string) {
    value = value.trim();
    this.instructionsEditMode = false;
    this.itemChange.emit({ ...this.item, instructions: value });
  }

  removeClick() {
    this.remove.emit(this.item);
  }

  cloneClick() {
    this.clone.emit(this.item);
  }

  removeRatingGroup() {
    if (this.ratingGroup) {
      this.itemChange.emit({
        ...this.item,
        elements: this.item.elements.filter(element => element.type !== 'propertystate'),
      });
    }
  }

  toggleTimeAndMaterial() {
    this.checkInspectionProPackageAndNotify$.subscribe(result => {
      if (!result) return;
      if (!this.isTAndMExist) {
        this.expandWorker.isExpanded = true;
      }
      this.itemChange.emit({
        ...this.item,
        timeCost: this.isTAndMExist ? null : 0,
        laborCost: this.isTAndMExist ? null : 0,
        materials: this.isTAndMExist ? null : 0,
      });
    });
  }

  scoreWeightChange(value: any) {
    if (!this.checkEditRight()) {
      return;
    }
    const selectedScoreWeight = value as String;
    if (selectedScoreWeight !== undefined && selectedScoreWeight !== null) {
      this.item.scoreWeightMode =
        selectedScoreWeight === ScoreWeightModes.Automatic ? ScoreWeightModes.Automatic : ScoreWeightModes.Manual;
      this.itemChange.emit({
        ...this.item,
        scoreWeight: this.item.scoreWeight,
      });
    }
  }

  addRatingGroup(ratingGroup: TemplateEditorRatingGroup) {
    this.expandWorker.isExpanded = true;
    if (this.item.compulsoryItems.includes('commentRequired')) {
      this.toggleCommentRequired();
    }
    if (this.item.compulsoryItems.includes('photoRequired')) {
      this.togglePhotoRequired();
    }
    setTimeout(() => {
      this.ratingGroupAdd.emit([this.item, ratingGroup]);
    }, 100);
  }

  addQuestion(question?: AreaPageElementQuestionModel) {
    this.expandWorker.isExpanded = true;
    if (this.questions.length < 35) {
      this.questionAdd.emit([
        question
          ? {
              ...this.item,
              elements: this.item.elements.map(element => {
                if (element.guid === question.guid) {
                  return question;
                } else {
                  return element;
                }
              }),
            }
          : this.item,
        null,
      ]);
    }
  }

  togglePhotoRequired() {
    this.toggleCompulsory('photoRequired');
  }

  toggleCommentRequired() {
    this.toggleCompulsory('commentRequired');
  }

  toggleInstructions() {
    this.toggleCompulsory('hasInstructions');
  }

  private toggleCompulsory(compulsoryItem: CompulsoryItems, updateRatingGroup = true) {
    const item = { ...this.item };
    const index = item.compulsoryItems.indexOf(compulsoryItem);
    const newCompulsoryState = index < 0;
    if (index >= 0) {
      item.compulsoryItems.splice(index, 1);
    } else {
      item.compulsoryItems.push(compulsoryItem);
    }

    const emojiElement = item.elements.find(e => e.type === 'propertystate');
    if (emojiElement?.type == 'propertystate') {
      emojiElement.items.forEach(e => {
        const itemIndex = e.item.onSelect.indexOf(compulsoryItem);
        if (newCompulsoryState && itemIndex < 0) {
          e.item.onSelect.push(compulsoryItem);
        }
        if (!newCompulsoryState && itemIndex >= 0) {
          e.item.onSelect.splice(itemIndex, 1);
        }
      });
      const emojiIndex = item.elements.indexOf(emojiElement);
      item.elements.splice(emojiIndex, 1, { ...emojiElement, items: [...emojiElement.items.map(e => ({ ...e }))] });
    }
    this.itemChange.emit(item);

    if (updateRatingGroup && this.ratingGroup) {
      this.ratingGroupChange(this.ratingGroup);
    }
  }

  checkEditRight(notify = true): boolean {
    return super.checkEditRight(this.isEditable, notify);
  }
  handleClickUseSameForParent(event: MouseEvent) {
    if (event?.shiftKey) event.stopPropagation();

    if (isEmpty(this.parent?.areaSurvey?.sparePartsGroups)) return;

    const getParentPartGroup = this.parent?.areaSurvey?.sparePartsGroups ?? [];

    this.itemChange.emit({
      ...this.item,
      overrideSparePartsSettings: false,
      sparePartsGroups: getParentPartGroup,
      showSpareParts: getParentPartGroup.length > 0,
    });
  }
  handleClickNotUseSpareParts(event: MouseEvent) {
    if (event?.shiftKey) event.stopPropagation();
    this.itemChange.emit({
      ...this.item,
      overrideSparePartsSettings: true,
      showSpareParts: false,
      sparePartsGroups: [],
    });
  }

  handleClickSparePart(event: MouseEvent, id: number) {
    if (event?.shiftKey) event.stopPropagation();

    const getNewParts = this.sparePartsService.useSetOrUnsetSparePartsGroupItem({
      parent: this.parent,
      item: this.item,
      id,
    });

    this.itemChange.emit({
      ...this.item,
      ...getNewParts,
    });
  }
}
