import { ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';

export function getActiveValidationErrorFunction(errors: ValidationErrors): ValidationErrors {
  const activeErrors: ValidationErrors = {};
  (Object.entries(errors) || [])
    .filter(([key, errorParams]) => !!errorParams)
    .forEach(([key, errorParams]) => {
      activeErrors[key] = errorParams;
    });
  return isEmpty(activeErrors) ? null : activeErrors;
}
