export enum Providers {
  APPFOLIO = 'AppFolio',
  INJECTER = 'Ingester',
  RESMAN = 'ResMan',
  ENTRATA = 'Entrata',
  BUILDIUM = 'Buildium',
  FORTRESS = 'Fortress',
  YARDI = 'Yardi',
}

export interface PmsCredentialModel {
  id: number;
  propertyIds: number[];
  type: Providers;
  isRunning?: boolean;
  dontAddNewProperties: boolean;
  configuration: PmsConfigurationModel;
  pmsSyncConfiguration?: PmsSyncConfigurationExtendedModel;
}

export interface PmsSyncConfigurationExtendedModel extends PmsSyncConfigurationModel {
  pmsStatuses: string;
  pmsCategories: string;
  pmsPriorities: string;
  pmsResolutions: string;
  pmsUsers: string;
}

export interface PmsSyncConfigurationModel {
  id: number;
  completedStatuses: string;
  residentTicketOrigins: string;
  pullTicketsWithSelectedOriginsOnly: string;
  doNotSendTicketCreatedForSelectedOrigins: string;
  statusesMap: string;
  pmswoOrigins: string;
  categoriesMap: string;
  usersMap: string;
  prioritiesMap: string;
  resolutionsMap: string;
  pushWOToPms: boolean;
  pullWoFromPMSPropertyIds: string;
  pushWoToPmsPropertyIds: string;
  useServiceTriggersForNullAssignee: boolean;
  importDefaultTopicId?: number | null;
  importDefaultAssigneeMode?: ImportDefaultAssigneeMode;
  importDefaultPriority?: TicketPriorityType;
  exportDefaultSubCategoryId: string | null;
  exportDefaultAssigneeId: string | null;
  exportDefaultOrigin: string | null;
  uploadWOAttachments: boolean;
}

export interface PmsConfigurationModel {
  base_Url?: string;
  password?: string;
  username?: string;
  lastImportDetails?: string;
  lastTimeUsed?: string;
  successfullyImported?: boolean;
  allowedSenderEmails?: string;
  reportFileFormat?: string;
}

export enum ImportDefaultAssigneeMode {
  TurnAssigneeOrPm = 0,
  Unassigned = 1,
}

export enum TicketPriorityType {
  Low = 0,
  Medium = 1,
  High = 2,
  Critical = 3,
}
