import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormControlStatus, FormGroupDirective, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { tap } from 'rxjs/operators';

import { getActiveValidationErrorFunction } from '@shared/functions/get-active-validation-error.function';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent extends CustomControlAbstract<any> implements OnInit, ControlValueAccessor {
  disabled = false;

  @Input() label = '';
  @Input() labelCss = 'nowrap body-small-bold';
  @Input() items: IRadioButtonOption<unknown>[] = [];
  @Input() labelRequired = false;
  @Input() radioGroupClass = 'display-flex';
  @Input() errorSection = true;
  @Input() markAsInvalid = false;

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  @Input() set attrDisable(attrDisable: boolean) {
    if (attrDisable) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    this.disabled = attrDisable;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.checkIfEmpty();
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        tap((value: any) => {
          this.setValue(value);
          this.checkIfEmpty();
        })
      )
      .subscribe();

    this.control.statusChanges
      .pipe(
        untilDestroyed(this),
        tap(status => this.checkControlStatus(status))
      )
      .subscribe();

    if (this.ngControl?.control) {
      if (this.ngControl.control.errors) {
        this.errors = { ...this.ngControl.control.errors };
      }
      this.checkStatus();
      this.ngControl.control.statusChanges
        .pipe(
          untilDestroyed(this),
          tap(status => this.checkControlStatus(status))
        )
        .subscribe();
    }
  }

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  setValue(value: unknown) {
    if (this.ngControl.control.disabled) {
      return;
    }
    this.onChanged(value);
    this.onTouched();
  }

  protected checkControlStatus(status: FormControlStatus) {
    super.checkControlStatus(status);
    this.checkIfEmpty();
  }

  /**
   *
   * @private
   * This method checks empty value if labelRequired params is set as true.
   * Its only visual designation - does not change the status of the control as valid or invalid
   */

  private checkIfEmpty() {
    const isEmpty = isNil(this.control?.value);
    this.isEmptyValue = this.labelRequired ? isEmpty : false;
    this.errors = getActiveValidationErrorFunction({
      ...this.ngControl?.control?.errors,
      ...{ required: this.isEmptyValue },
    });
    this.cdr.detectChanges();
  }
}
