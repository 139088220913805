import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EIcon } from '@shared/enums/icon.enum';
import { ImageOrientation } from '@shared/interfaces';

import { InspectionStepModel } from '../../models/inspection-step.model';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoViewerComponent {
  EIcon = EIcon;
  currentUrl: { cloudUri: string; thumbnailFileUploadCloudUri?: any; imageOrientation?: ImageOrientation };
  deletedImages: string[] = [];
  protected readonly ImageOrientation = ImageOrientation;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      step: InspectionStepModel;
      imageUrls: { cloudUri: string; thumbnailFileUploadCloudUri: any; imageOrientation?: ImageOrientation }[];
      initialIndex: number;
      isEditorMode?: boolean;
    },
    private dialogRef: MatDialogRef<PhotoViewerComponent>
  ) {
    this.currentUrl = this.data.imageUrls[this.data.initialIndex] || this.data.imageUrls[0];
  }

  onChange(mediaUrl: string): void {
    this.currentUrl = this.data.imageUrls.find(el => el.cloudUri === mediaUrl);
  }

  close(): void {
    this.dialogRef.close({
      deletedImages: this.deletedImages,
    });
  }

  deleteImage(cloudUri: string): void {
    const index = this.data.imageUrls.findIndex(el => el.cloudUri === cloudUri);
    if (index !== -1) {
      this.deletedImages.push(cloudUri);
      this.data.imageUrls.splice(index, 1);

      if (this.data.imageUrls.length > 0) {
        this.currentUrl = this.data.imageUrls[0];
      } else {
        this.close();
      }
    }
  }
}
