import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FileUploadsService } from '@app/services/file-uploads.service';
import { PhotoViewerComponent } from '@main-application/inspections/components/photo-viewer/photo-viewer.component';
import { ButtonMenuComponent } from '@shared/components/button/button-menu/button-menu.component';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { propertyStateValidator } from '@shared/validators/propertystate.validator';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { MultipleAttachmentsModule } from '@ui-components/components/multiple-attachments/multiple-attachments.module';

import { TemplateEditorDialogModule } from '../../../main-application/shared/template-editor-dialog/template-editor-dialog.module';
import { DialogResult } from '../config/dialog-result.enum';

@Component({
  selector: 'app-resident-inspect-details',
  templateUrl: './resident-inspect-details.component.html',
  styleUrls: ['./resident-inspect-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    IconComponent,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    TemplateEditorDialogModule,
    ButtonMenuComponent,
    MultipleAttachmentsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResidentInspectDetailsComponent implements OnInit {
  cleanInputAttachment = new EventEmitter<void>();
  EColorPalette = EColorPalette;
  EIcon = EIcon;
  inspectForm: FormGroup;
  dialogRef: MatDialogRef<any>;
  currentItemIndex = 0;
  totalItems: number;
  onSubmitOptions = [
    {
      label: 'Done & Move Next',
      id: false,
    },
    {
      label: 'Done',
      id: true,
    },
  ];
  onSubmitSelected = this.onSubmitOptions[0];
  inspectionId: number;
  inspectionContentId: number;
  isCommentRequired = false;
  isPhotoRequired = false;
  mappedImages = [];
  thumbnailPlaceholder = '../../../../../../../assets/icon/zoom-in.svg';
  isUploading = false;

  @ViewChild('instructionsSheet') instructionsSheet: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<ResidentInspectDetailsComponent>,
    private fileUploadsService: FileUploadsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mappedImages = this.data.mappedImages || [];
    this.currentItemIndex = this.data.startIndex;
    this.totalItems = this.data.items.length;
    this.initializeForm(this.data.items[this.currentItemIndex]);

    const instructionKey = this.getInstructionKey(this.data.items[this.currentItemIndex]);
    if (
      !this.isInstructionDismissed(instructionKey) &&
      this.data.items[this.currentItemIndex].hasInstructions &&
      this.data.items[this.currentItemIndex].instructions
    ) {
      setTimeout(() => {
        this.openInstructions(this.data.items[this.currentItemIndex].instructions);
      }, 300);
    }
  }

  initializeForm(data: any) {
    this.inspectionId = data.inspectionId;
    this.inspectionContentId = data.id;
    if (this.inspectForm) this.inspectForm.reset();
    this.inspectForm = this.fb.group({
      emojiDetails: [data.emojiDetails || [], propertyStateValidator],
      itemName: [data.itemName || '', Validators.required],
      booleanQuestions: this.fb.array(this.createBooleanQuestions(data.booleanQuestions || [])),
      comment: [data.comment || null],
      fileUploadIds: [data.fileUploadIds || []],
      images: [data.images || []],
    });

    if (data.emojiDetails && data.emojiDetails.length > 0) {
      this.setValidatorsBasedOnPropertyState();
    } else {
      this.setValidatorsWithoutPropertyState(data);
    }
  }

  setEmojiDefault(index: number) {
    const emojis = this.inspectForm.get('emojiDetails').value;
    emojis.forEach((emoji: any, i: number) => {
      emoji.isDefault = i === index;
    });
    this.inspectForm.get('emojiDetails').setValue(emojis);
    this.inspectForm.get('emojiDetails').updateValueAndValidity();

    this.setValidatorsBasedOnPropertyState();
  }

  setValidatorsWithoutPropertyState(data: any) {
    this.isCommentRequired = data.commentRequired;
    this.isPhotoRequired = data.photoRequired;

    const commentControl = this.inspectForm.get('comment');
    if (this.isCommentRequired) {
      commentControl.setValidators(Validators.required);
    } else {
      commentControl.clearValidators();
    }
    commentControl.updateValueAndValidity();

    const fileUploadIdsControl = this.inspectForm.get('fileUploadIds');
    if (this.isPhotoRequired) {
      fileUploadIdsControl.setValidators(Validators.required);
    } else {
      fileUploadIdsControl.clearValidators();
    }
    fileUploadIdsControl.updateValueAndValidity();
  }

  setValidatorsBasedOnPropertyState() {
    const emojis = this.inspectForm.get('emojiDetails').value;
    const selectedEmoji = emojis.find((emoji: any) => emoji.isDefault);

    this.isCommentRequired = selectedEmoji?.onSelect?.includes('commentRequired') || false;
    this.isPhotoRequired = selectedEmoji?.onSelect?.includes('photoRequired') || false;

    const commentControl = this.inspectForm.get('comment');
    if (this.isCommentRequired) {
      commentControl.setValidators(Validators.required);
    } else {
      commentControl.clearValidators();
    }
    commentControl.updateValueAndValidity();

    const fileUploadIdsControl = this.inspectForm.get('fileUploadIds');
    if (this.isPhotoRequired) {
      fileUploadIdsControl.setValidators(Validators.required);
    } else {
      fileUploadIdsControl.clearValidators();
    }
    fileUploadIdsControl.updateValueAndValidity();
  }

  createBooleanQuestions(questions: any[] = []): FormGroup[] {
    return questions.map(question =>
      this.fb.group({
        question: [question.question, Validators.required],
        answer: [question.answer === 'true' ? true : question.answer === 'false' ? false : null, Validators.required], // BE requires true and false in the form of strings
        guid: [question.guid],
      })
    );
  }

  get booleanQuestions(): FormArray {
    return this.inspectForm.get('booleanQuestions') as FormArray;
  }

  handleAttachment(attachments: AttachmentItem[]) {
    if (attachments?.length) {
      const mainImageArray = this.inspectForm.get('fileUploadIds').value || [];
      const imagesArray = this.inspectForm.get('images').value || [];

      attachments.forEach(attachment => {
        const fileData = attachment.upload;

        mainImageArray.push(fileData.id);

        imagesArray.push({
          cloudUri: fileData.cloudUri,
        });

        this.mappedImages.push({
          cloudUri: fileData.cloudUri,
          fileUploadId: fileData.id,
        });
      });

      this.inspectForm.get('fileUploadIds').setValue([...mainImageArray]);
      this.inspectForm.get('images').setValue([...imagesArray]);
      this.cleanInputAttachment.emit();
      this.cdr.detectChanges();
    }
  }

  viewAllPhotos(images: any[], isEditorMode = true, initialIndex = 0) {
    if (!images || images.length === 0) {
      return;
    }

    const imageUrls = images.map(image => ({
      cloudUri: image.cloudUri,
      thumbnailFileUploadCloudUri: image.thumbnailUri,
    }));

    if (imageUrls.length > 0) {
      const config: MatDialogConfig = {
        maxWidth: '100vw',
        minWidth: 'min(500px, 100vw)',
        height: '100vh',
        panelClass: 'photo-viewer-modal-container',
        data: {
          imageUrls,
          isEditorMode,
          initialIndex,
        },
      };

      const dialogRef = this.dialog.open(PhotoViewerComponent, config);

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const deletedImages = result.deletedImages || [];
          const deletedFileUploadIds = this.mappedImages
            .filter(image => deletedImages.includes(image.cloudUri))
            .map(image => image.fileUploadId);

          const updatedFileUploadIds = this.inspectForm
            .get('fileUploadIds')
            .value.filter(id => !deletedFileUploadIds.includes(id));
          this.inspectForm.get('fileUploadIds').setValue(updatedFileUploadIds);

          const updatedImages = this.inspectForm
            .get('images')
            .value.filter(image => !deletedImages.includes(image.cloudUri));
          this.inspectForm.get('images').setValue(updatedImages);
          this.cdr.markForCheck();
        }
      });
    }
  }

  isImage(url: string): boolean {
    return (
      url?.toLowerCase().endsWith('.jpg') ||
      url?.toLowerCase().endsWith('.jpeg') ||
      url?.toLowerCase().endsWith('.png') ||
      url?.toLowerCase().endsWith('.gif')
    );
  }

  submitForm(isDone = false) {
    if (this.inspectForm.invalid) {
      this.inspectForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
      return;
    }

    if (this.inspectForm.valid) {
      const updatedData = this.inspectForm.value;
      const currentItem = this.data.items[this.currentItemIndex];

      currentItem.surveyJSON.pages.forEach((page, pageIndex) => {
        page.elements.forEach((element, elementIndex) => {
          if (element.type === 'comment') {
            currentItem.surveyJSON.pages[pageIndex].elements[elementIndex].value = updatedData.comment;
          }

          if (element.type === 'file' && element.guid === updatedData.guid) {
            currentItem.surveyJSON.pages[pageIndex].elements[elementIndex].mainImage = updatedData.fileUploadIds;
          }

          if (element.type === 'boolean') {
            const updatedBoolean = updatedData.booleanQuestions?.find(q => q.question === element.title);
            if (updatedBoolean) {
              currentItem.surveyJSON.pages[pageIndex].elements[elementIndex].value = updatedBoolean.answer;
            }
          }
        });
      });

      this.data.items[this.currentItemIndex] = {
        ...currentItem,
        emojiDetails: updatedData.emojiDetails,
        comment: updatedData.comment,
        booleanQuestions: updatedData.booleanQuestions,
        fileUploadIds: updatedData.fileUploadIds,
        surveyJSON: currentItem.surveyJSON,
      };

      if (isDone || this.currentItemIndex === this.totalItems - 1) {
        this.bottomSheetRef.dismiss({
          result: DialogResult.Success,
          data: this.data.items,
        });
      } else {
        this.currentItemIndex++;
        this.initializeForm(this.data.items[this.currentItemIndex]);
      }
    }
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = document.querySelector('form .ng-invalid') as HTMLElement;

    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstInvalidControl.focus();
    }
  }

  markAs(id: boolean): void {
    this.onSubmitSelected = this.onSubmitOptions.find(el => el.id === id);
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  openInstructions(instructions: string) {
    this.dialogRef = this.dialog.open(this.instructionsSheet, {
      hasBackdrop: true,
      autoFocus: false,
      panelClass: 'instructions-container',
      position: { bottom: '0px' },
      width: '100vw',
      minWidth: '100vw',
      maxWidth: '100vw',
      data: { instructions },
    });
  }

  closeInstructions(doNotShowAgain = false): void {
    if (doNotShowAgain) {
      const instructionKey = this.getInstructionKey(this.data.items[this.currentItemIndex]);
      this.markInstructionAsDismissed(instructionKey);
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  getInstructionKey(item: any): string {
    return `${LocalStorageDataEnum.INSTRUCTIONS_DISMISSED}-${item.inspectionId}-${item.inspectionTemplateAreaId}-${item.uuid}`;
  }

  isInstructionDismissed(key: string): boolean {
    return localStorage.getItem(key) === 'true';
  }

  markInstructionAsDismissed(key: string): void {
    localStorage.setItem(key, 'true');
  }
}
