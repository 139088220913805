import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, from } from 'rxjs';
import { filter, map, mergeMap, reduce, tap } from 'rxjs/operators';

import { FileUploadsService } from '@app/services/file-uploads.service';
import { checkSupportedFormatFunction } from '@shared/functions/check-supported-format.function';
import { Upload } from '@shared/interfaces/upload';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileComponent extends CustomControlAbstract<any> implements OnInit, ControlValueAccessor {
  errors: ValidationErrors;
  invalid = false;
  disabled = false;

  @Input() label = '';
  @Input() labelRequired = false;
  @Input() labelCss = 'nowrap body-small-bold';
  @Input() contentCss = 'display-flex  align-items-start';
  @Input() containerCss = 'display-flex flex-column';
  @Input() inputCss = '';
  @Input() multiselect = false;
  @Input() acceptedFormats = ['application/pdf'];
  @Input() attrPlaceholder = 'Upload a file';
  @Input() uploadSelection = false;
  @Input() attrDisable = false;
  @Input() isFileLoaded: boolean | null = null;
  @Input() labelTooltipText: string = null;
  @Input() isResident = false;

  @Input() set value(value: string) {
    this.control.setValue(value);
  }

  @Output() clearEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectFilesEvent: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() uploadInProgress = new EventEmitter<boolean>();

  @ViewChild('fileUpload', { static: true }) input: ElementRef;

  uploadingFile$ = new BehaviorSubject<File | null>(null);
  uploadingProgress$ = new BehaviorSubject<number>(0);

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    @Optional() formDirective: FormGroupDirective,
    protected cdr: ChangeDetectorRef,
    private fileUploadsService: FileUploadsService,
    private snackbarService: SnackbarService
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngOnInit(): void {
    this.initControlChanges();
    this.initCheckControl();
  }

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  browseFile() {
    this.input.nativeElement.value = '';
    this.input.nativeElement.click();
  }

  clear() {
    this.control.reset();
    this.clearEvent.emit();
    this.cdr.detectChanges();
  }

  filesDropped(files: File[]) {
    this.uploadSelection ? this.uploadFile(files) : this.emitEvents(files);
  }

  inputFileChanged($event: Event) {
    const files: FileList = ($event.target as HTMLInputElement).files;
    const filesList = Object.entries(files).map<File>(([key, file]) => file);
    this.uploadSelection ? this.uploadFile(filesList) : this.emitEvents(filesList);
  }

  private emitEvents(files: File[]) {
    if (files && files.length) {
      this.selectFilesEvent.emit(files);
      this.control.setValue(files);
      this.cdr.detectChanges();
    }
  }

  private uploadFile(files: File[]) {
    const filesToUpload = files.filter(file => checkSupportedFormatFunction(file.type, this.acceptedFormats));
    if (filesToUpload.length > 0) {
      this.uploadingFile$.next(filesToUpload[0]);
      this.uploadingProgress$.next(0);
      this.uploadInProgress.emit(true);
      this.uploadQueue(filesToUpload);
    } else {
      this.snackbarService.error(SnackbarErrorMessage.UnsupportedFileType);
    }
  }

  private uploadQueue(files: File[]) {
    from(files)
      .pipe(
        mergeMap((file: File) => this.doUpload(file), 1),
        reduce((acc, value) => {
          acc.push(value);
          return acc;
        }, [])
      )
      .subscribe({
        next: result => {
          this.control.setValue(result);
          this.uploadingProgress$.next(100);
          this.cdr.detectChanges();
        },
        complete: () => {
          this.uploadInProgress.emit(false);
        },
      });
  }

  private doUpload(file: File) {
    return this.fileUploadsService
      .uploadFileProgress(file)
      .pipe(
        catchError((err: unknown) => {
          this.snackbarService.error(SnackbarErrorMessage.UploadingFile);
          this.uploadingProgress$.next(0);
          throw err;
        })
      )
      .pipe(
        tap((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round((100 * event.loaded) / event.total);
            this.uploadingProgress$.next(progress);
            this.cdr.detectChanges();
          }
        }),
        filter((event: any) => {
          return event instanceof HttpResponse;
        }),
        map((event: any) => {
          this.uploadingProgress$.next(0);
          this.cdr.detectChanges();
          const upload = event.body as Upload;
          return upload;
        })
      );
  }

  private initCheckControl(): void {
    if (this.ngControl?.control) {
      if (this.ngControl.control.errors) {
        this.errors = { ...this.ngControl.control.errors };
      }
      if (this.ngControl.control.touched) {
        this.control.markAsTouched();
      }
      this.invalid = this.ngControl.control.invalid;
      this.ngControl.control.statusChanges
        .pipe(untilDestroyed(this))
        .subscribe(status => this.checkControlStatus(status));
    }
  }

  private initControlChanges(): void {
    this.control.valueChanges
      .pipe(
        tap(value => {
          this.onChanged(value);
          this.onTouched();
          if (!value) {
            this.uploadingFile$.next(null);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
